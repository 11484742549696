// @ts-nocheck
import React from 'react';
import {
  InfoContainer,
  InfoWrapper,
  InfoRow,
  Column1,
  Column2,
  TextWrapper,
  TopLine,
  Heading,
  Subtitle,
  BtnWrap,
  ImgWrap,
  Img,
} from './InfoElements';
import { Button } from '../../components/buttons/ButtonElements';
import { NEW_USER_FLOW_PATH } from '../../constants/PathConstants';
import { useHistory } from 'react-router-dom';
// import reactImage from '../assets/images/react.svg'

type InfoSectionProps = {
  id: string;
  lightBg: boolean;
  imgStart: boolean;
  topLine: string;
  lightText: boolean;
  lightTextDescription: boolean;
  darkText: boolean;
  headLine: string;
  description: string;
  buttonLabel: string;
  img: any;
  alt: string;
  primary: boolean;
  dark: boolean;
};

const InfoSection = ({
  id,
  lightBg,
  imgStart,
  topLine,
  lightText,
  lightTextDescription,
  darkText,
  headLine,
  description,
  buttonLabel,
  img,
  alt,
  primary,
  dark,
}: InfoSectionProps) => {
  const history = useHistory();

  return (
    <>
      <InfoContainer lightBg={lightBg} id={id}>
        <InfoWrapper>
          <InfoRow imgStart={imgStart}>
            <Column1>
              <TextWrapper>
                <TopLine>
                  {/* Create SVGs for your configurators easily */}
                  {topLine}
                </TopLine>
                <Heading lightText={lightText}>
                  {/* With the Map Tool */}
                  {headLine}
                </Heading>
                <Subtitle
                  darkText={darkText}
                  // lightTextDescription={lightTextDescription}
                >
                  {/* Quickly and easily draw available lot using the drawing tool */}
                  {description}
                </Subtitle>
                <BtnWrap>
                  <Button
                    // to='/signup'
                    onClick={() => {
                      history.push(NEW_USER_FLOW_PATH);
                    }}
                    smooth={true}
                    duration={500}
                    spy={true}
                    exact="true"
                    offset={-80}
                    primary={primary}
                    dark={dark}
                    // dark2={dark2 ? 1 : 0}
                  >
                    {/* Try it for free */}
                    {buttonLabel}
                  </Button>
                </BtnWrap>
              </TextWrapper>
            </Column1>
            <Column2>
              <ImgWrap>
                <Img src={img} alt={alt} />
              </ImgWrap>
            </Column2>
          </InfoRow>
        </InfoWrapper>
      </InfoContainer>
    </>
  );
};

export default InfoSection;
