import i18n, { Resource } from "i18next";
import { initReactI18next } from "react-i18next";
import translationEN from "./locales/en/translation.json";
import translationNL from "./locales/nl/translation.json";

export const supportedLanguages = {
	en: "en",
	nl: "nl",
};

const resources: Resource = {
	en: {
		translation: translationEN,
	},
	nl: {
		translation: translationNL,
	},
};

i18n.use(initReactI18next).init({
	resources,
	// fallbackLng: "en",
	lng: "en",
	debug: false,
	react: {
		useSuspense: false,
	},
});

export default i18n;
