import styled from 'styled-components';

export const FormWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  //align-items: center;

  label.projectName,
  label.numberOfUnits {
    font-size: 17px;
    font-family: Silka;
  }

  input.project-name {
    width: 250px;
    height: 48px;
    padding: 0 20px;
    font-size: 16px;
    border: 1px solid transparent;
    transition: all 0.23s;
    border-radius: 10px;

    &::placeholder {
      font-size: 14px;
      line-height: 16px;
    }

    &:hover,
    &:focus-visible {
      outline: none;
      border: 1px solid gray;
    }

    &::placeholder {
      color: #a7a7a7;
    }
  }

  input.number-of-units {
    width: 163px;
    height: 48px;
    border-radius: 10px;
    padding: 0 20px;
    font-size: 16px;
    border: 1px solid transparent;
    transition: all 0.23s;

    &::placeholder {
      font-size: 14px;
      line-height: 16px;
    }

    &:hover,
    &:focus-visible {
      outline: none;
      border: 1px solid gray;
    }

    &::placeholder {
      color: #a7a7a7;
    }
  }

  .errorText {
    bottom: -30px;
  }
`;

export const NewProjectWrapper = styled.div`
  width: 100%;
  margin-top: 100px;
  background: #dbdbdb;
  padding: 30px;
  display: flex;
  flex-direction: column;
  position: relative;

  h1 {
    font-size: 31px;
    padding-bottom: 30px;
  }

  .addProject {
    font-size: 19px;
    cursor: pointer;
    transition: all 0.23s;
    border-radius: 0;
    position: relative;
    align-self: end;
    margin-right: 0 !important;

    &:hover {
      background: #0064d6;
      color: white;
    }
  }

  .blocked {
    cursor: not-allowed !important;

    &:hover {
      background: red !important;
    }
  }
`;
