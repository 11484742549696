import React, { useEffect, useRef, useState } from "react";
import styled from "styled-components";

const DropDownContainer = styled("div")`
	position: relative;
	margin: 0 auto;
`;

const DropDownHeader = styled("div")`
	display: flex;
	align-items: center;
	justify-content: center;
	color: #000000;
`;

const DropDownListContainer = styled("div")`
	position: absolute;
	width: 7em;
	top: -20px;
	z-index: 5;
`;

const DropDownList = styled("ul")`
	padding: 0;
	margin: 0;
	background: #ffffff;
	border: 2px solid #e5e5e5;
	box-sizing: border-box;

	&:first-child {
		padding-top: 5px;
	}

	&:last-child {
		padding-bottom: 5px;
	}
`;

const ListItem = styled("li")<{ isSelected: boolean }>`
	background: ${props => props.isSelected && "#E6E6E6"};
	list-style: none;
	height: 30px;
	color: black;
	display: flex;
	align-items: center;
	padding-left: 3px;
`;
export const ColoredCircle = styled("div")<{ color: string }>`
	width: 10px;
	height: 10px;
	border-radius: 10px;
	background: ${props => props.color};
	margin-right: 5px;
`;

export default function SelectCustom({
	data,
	selected,
	onChange,
	save,
}: {
	data: [any];
	selected: any;
	onChange: (e: any) => void;
	save: () => void;
}) {
	const [isOpen, setIsOpen] = useState(false);
	const toggling = () => setIsOpen(!isOpen);
	const [selectedOption, setSelectedOption] = useState<any>(selected);
	const [hoveredOption, setHoveredOption] = useState<any>(selected);
	const onOptionClicked = (value: any) => {
		onChange(value);
		setSelectedOption(value);
		setIsOpen(false);
	};
	const elementRef = useRef();
	const ref = useRef();

	function useOnClickOutside(ref: any, handler: any) {
		useEffect(() => {
			const listener = (event: any) => {
				// Do nothing if clicking ref's element or descendent elements
				if (!ref.current || ref.current.contains(event.target)) {
					return;
				}
				handler(event);
			};
			document.addEventListener("mousedown", listener);
			document.addEventListener("touchstart", listener);
			return () => {
				document.removeEventListener("mousedown", listener);
				document.removeEventListener("touchstart", listener);
			};
		}, [ref, handler]);
	}

	const [options, setOptions] = useState<any>(data);
	useOnClickOutside(elementRef, () => {
		setIsOpen(false);
	});
	useOnClickOutside(ref, () => {
		save();
	});

	return (
		// @ts-ignore
		<div className='SelectCustom' style={{ zIndex: 10 }} ref={ref}>
			<DropDownContainer>
				{!isOpen && (
					<DropDownHeader onClick={toggling}>
						{selectedOption === "Offer" && <ColoredCircle color={"#FF9900"} />}
						{selectedOption === "Available" && <ColoredCircle color={"#00FF22"} />}
						{selectedOption === "Sold" && <ColoredCircle color={"#FF0000"} />}
						{selectedOption || "Choose"}
					</DropDownHeader>
				)}
				{isOpen && (
					// @ts-ignore
					<DropDownListContainer ref={elementRef}>
						<DropDownList>
							{options.map((option: any) => (
								<ListItem
									isSelected={option === hoveredOption}
									onMouseOut={() => setHoveredOption(null)}
									onMouseOver={() => {
										setHoveredOption(option);
									}}
									onClick={() => onOptionClicked(option)}
									key={Math.random()}
								>
									{option === "Offer" && <ColoredCircle color={"#FF9900"} />}
									{option === "Available" && <ColoredCircle color={"#00FF22"} />}
									{option === "Sold" && <ColoredCircle color={"#FF0000"} />}
									{option}
								</ListItem>
							))}
						</DropDownList>
					</DropDownListContainer>
				)}
			</DropDownContainer>
		</div>
	);
}
