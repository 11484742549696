import { AnyAction } from 'redux';
import { ProjectState } from 'types/state';
import { GET_PROJECT } from '../actions/Actions';

const initialState: ProjectState = {
  project: null,
};

export const projectReducer = (state = initialState, action: AnyAction) => {
  switch (action.type) {
    case GET_PROJECT:
      return {
        ...state,
        project: action.payload,
      };
    default:
      return { ...state };
  }
};
