import React, { useState } from 'react';
// import Video from "../../video/reactapp.mp4";
import { Button } from '../../components/buttons/ButtonElements';
import {
  ArrowForward,
  ArrowRight,
  HeroBtnWrapper,
  HeroContainer,
  HeroContent,
  HeroH1,
  HeroP,
} from './HeroSectionElements';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import { NEW_USER_FLOW_PATH } from '../../constants/PathConstants';

const HeroSection = () => {
  const { t } = useTranslation();

  const history = useHistory();

  const [hover, setHover] = useState(false);

  const onHover = () => {
    setHover(!hover);
  };

  return (
    <HeroContainer>
      {/* <HeroBg> */}
      {/*	<VideoBg autoplay loop muted src={Video} type='video/mp4' /> */}
      {/* </HeroBg> */}
      <HeroContent>
        <HeroH1>Map Tool</HeroH1>
        <HeroP>{t('landing.tryAccount')}</HeroP>
        <HeroBtnWrapper>
          <Button
            // to='signup'
            onClick={() => {
              history.push(NEW_USER_FLOW_PATH);
            }}
            onMouseEnter={onHover}
            onMouseLeave={onHover}
            primary
            dark
          >
            {t('landing.getStarted')}
            {hover ? <ArrowForward /> : <ArrowRight />}
          </Button>
        </HeroBtnWrapper>
      </HeroContent>
    </HeroContainer>
  );
};

export default HeroSection;
