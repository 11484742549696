import React from "react";
import Icon1 from "../../images/react.svg";
import Icon2 from "../../images/upload_image.svg";
import Icon3 from "../../images/web_devices.svg";
import {
	ServicesCard,
	ServicesContainer,
	ServicesH1,
	ServicesH2,
	ServicesIcon,
	ServicesP,
	ServicesWrapper,
} from "./ServicesElements";
import { useTranslation } from "react-i18next";

const Services = () => {
	const { t } = useTranslation();

	return (
		<>
			<ServicesContainer>
				<ServicesH1>{t("landing.services.services")}</ServicesH1>
				<ServicesWrapper>
					<ServicesCard>
						<ServicesIcon src={Icon1} />
						<ServicesH2>{t("landing.services.firstService.title")}</ServicesH2>
						<ServicesP> {t("landing.services.firstService.text")} </ServicesP>
					</ServicesCard>
					<ServicesCard>
						<ServicesIcon src={Icon2} />
						<ServicesH2>{t("landing.services.secondService.title")}</ServicesH2>
						<ServicesP> {t("landing.services.secondService.text")}</ServicesP>
					</ServicesCard>
					<ServicesCard>
						<ServicesIcon src={Icon3} />
						<ServicesH2>{t("landing.services.thirdService.title")}</ServicesH2>
						<ServicesP> {t("landing.services.thirdService.text")}</ServicesP>
					</ServicesCard>
				</ServicesWrapper>
			</ServicesContainer>
		</>
	);
};

export default Services;
