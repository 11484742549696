import React, { useEffect, useState } from "react";
import styled from "styled-components";
import ImageUploader from "../components/ImageUploader/ImageUploader";
import { toast } from "react-toastify";
import { v4 as uuidv4 } from "uuid";
import { loadImageDownloadUrl, saveImage } from "../../persistance/persistence";
import { FormWrapper } from "../pages/new-project/styles";

const Wrapper = styled.div`
	width: 73%;
	height: 70%;
	padding-left: 40px;
	flex-direction: column;
	display: flex;
`;

const Title = styled.text`
	font-family: Silka, serif;
	font-size: 1.37rem;
	font-weight: 500;
	margin-bottom: 53px;
`;

const Text = styled.text`
	font-family: Silka, serif;
	font-size: 1.06rem;
	margin-bottom: 30px;
`;

const UploadedImage = styled.div`
	position: absolute;
	right: 24px;
	top: 31px;
`;

export const UploadImage = (props: {
	imageUrl: string;
	setImageUrl: (url: string) => void;
	onSubmit: () => void;
}) => {
	const [file, setFile] = useState<any>();
	const uploadImage = async (e: any) => {
		const file = e.target.files[0];
		setFile(file);
		toast.info("Image is uploading...");
		const url = uuidv4();
		await saveImage(url, file);
		const ariaPreViewImage = await loadImageDownloadUrl(url);
		props.setImageUrl(ariaPreViewImage || "");
		toast.success("Image uploaded");
	};

	useEffect(() => {
		if (props.imageUrl !== "") {
			props.onSubmit();
		}
	}, [props.imageUrl]);

	return (
		<Wrapper>
			<Title>Upload image</Title>
			<Text>Upload image</Text>
			<ImageUploader
				text={`Max file size: 5 mb; minimal resolution: 1920 * 1020 px; extension: image/jpeg,image/png `}
				onChange={uploadImage}
				noMargin
			/>
			<FormWrapper>
				<UploadedImage>
					{file && (
						<img
							style={{
								width: "250px",
								maxHeight: "212px",
								height: "auto",
							}}
							src={URL.createObjectURL(file)}
						/>
					)}
				</UploadedImage>
			</FormWrapper>
		</Wrapper>
	);
};
