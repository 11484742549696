import React, { useState } from "react";
import { IFramePopUp } from "../../../tool/components/drawing-view/IFramePopUp";
import { PublishIframe } from "./styles";
import * as BiIcons from "react-icons/bi";

export const IFrameClipboardButton = (props: {
	projectId?: string;
	className?: string;
}) => {
	const [isDialogOpen, setIsDialogOpen] = useState<boolean>(false);

	const iframeContent = `
    <iframe id="iframe-wmdule" 
        src="${window.location.origin}/view/${props.projectId}" 
        height="700px" 
        width="100%" 
        frameborder="0" 
        scrolling="no">
    </iframe>
  `;

	return (
		<>
			<PublishIframe
				onClick={() => {
					setIsDialogOpen(true);
				}}
			>
				<BiIcons.BiCodeAlt />
				Iframe
			</PublishIframe>
			<IFramePopUp
				isPopupOpen={isDialogOpen}
				iFrameContent={iframeContent}
				handleClose={() => {
					setIsDialogOpen(false);
				}}
			/>
		</>
	);
};
