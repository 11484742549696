import { combineReducers } from 'redux';
import { RootState } from 'types/state';
import { newUserFlowReducer } from './NewUserFlowReducer';
import { fetchReducer } from './FetchReducer';
import { userReducer } from './UserReducer';
import { projectReducer } from './ProjectReducer';
import { validationReducer } from './ValidationReducer';
import { toolReducer } from './Toolreducer';

export default combineReducers<RootState>({
  user: userReducer,
  newUserFlow: newUserFlowReducer,
  fetch: fetchReducer,
  project: projectReducer,
  validation: validationReducer,
  tool: toolReducer,
});
