import React, { useState } from 'react';
import styled from 'styled-components';
import { ProjectInfo } from './ProjectInfo';
import { UploadImage } from './UploadImage';
import ProjectService from '../../services/ProjectService';
import { Project } from '../../types/Project';
import { useHistory } from 'react-router-dom';
import { PROJECT_DRAW_PATH } from '../constants/PathConstants';
import { TOOL_VIEW_SET } from '../redux/actions/Actions';
import { useDispatch } from 'react-redux';

const Wrapper = styled.div`
  max-width: 1266px;
  width: 90%;
  height: 330px;
  background: #dbdbdb;
  position: absolute;
  top: 20%;
  display: flex;
  align-items: center;

  &.notActive {
    display: none;
  }
`;

export const ModalWindow = (props: { project?: Project; isActive: boolean }) => {
  const [imageUrl, setImageUrl] = useState<string>('');
  const history = useHistory();
  const dispatch = useDispatch();

  return (
    <Wrapper className={props.isActive ? '' : 'notActive'}>
      <ProjectInfo projectId={props.project?.primaryId} houses={props.project?.houses.length} />
      <UploadImage
        imageUrl={imageUrl}
        setImageUrl={setImageUrl}
        onSubmit={async () => {
          props.project!.aerialViewImage = imageUrl;
          const updatedProject = await ProjectService.updateProject(props.project?.id, props.project);

          dispatch({ type: TOOL_VIEW_SET, payload: true });
          history.push(PROJECT_DRAW_PATH + updatedProject.data.primaryId);
        }}
      />
    </Wrapper>
  );
};
