import styled from 'styled-components';

export const HouseChangeWrapper = styled.div<{ top: any; left: any }>`
  position: absolute;
  top: ${(props) => props.top}px;
  left: ${(props) => props.left - 77}px;
  z-index: 999;
  background-color: white;
  width: 190px;
  text-align: center;
  max-height: 230px;
  display: flex;
  align-items: center;
  flex-direction: column;
  border-radius: 3px;
  overflow: auto;

  h2 {
    font-size: 14px;
    font-weight: bold;
    padding-bottom: 5px;
  }

  div {
    width: 100%;
    background-color: #f1f1f1;
    height: 100%;

    p {
      width: 100%;
      text-align: left;
      padding: 5px 7px;

      &:hover {
        background-color: #508ef2;
      }
    }
  }
`;

export const SearchBar = styled.input`
  width: 100%;
  border: none;
  background-color: #f1f1f1;
  padding: 10px 7px;
  position: sticky;
  top: 0;
`;
