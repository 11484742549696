import styled from 'styled-components';

export const Container = styled.div`
  width: 291px;
  height: 300px;
  background: white;
  position: relative;
  transition: all 0.3s;

  img {
    width: 100%;
    height: 100%;
    cursor: pointer;
    object-fit: cover;
    max-height: 164px;
  }

  p {
    margin: 10px;
    font-size: 18px;
    line-height: 20px;
  }

  .btn-wrapper {
    display: none;
    justify-content: center;
    width: 100%;
    height: 52px;
    position: absolute;
    bottom: 0;
    background: #e6e6e6;

    div {
      padding: 0 10px;
    }
  }

  &:hover {
    box-shadow: 0 5px 10px #000000;

    &,
    img {
      border-top-left-radius: 5px;
      border-top-right-radius: 5px;
    }

    span {
      display: flex;
    }
  }
`;

export const NumberOfHouses = styled.span`
  display: none;
  justify-content: center;
  align-items: center;
  position: absolute;
  top: 145px;
  right: 15px;
  width: 46px;
  height: 46px;
  border-radius: 50%;
  background: #3392ff;
  font-family: 'Lato', sans-serif;
  font-size: 16px;
  color: white;
`;

export const DeleteButton = styled.span`
  display: none;
  position: absolute;
  justify-content: center;
  align-items: center;
  top: -10px;
  right: -10px;
  width: 22px;
  height: 22px;
  background: #ff0000;
  border-radius: 50%;
  cursor: pointer;
  transition: all 0.3s;
  z-index: 3;

  .close-btn {
    color: white;
  }
`;

export const PublishIframe = styled.div`
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  font-family: 'Lato', sans-serif;
  opacity: 0.5;
  transition: opacity 0.4s;

  &:hover {
    opacity: 1;
  }
`;

export const SvgDownload = styled.div`
  font-family: 'Lato', sans-serif;
  opacity: 0.5;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  transition: opacity 0.4s;

  &:hover {
    opacity: 1;
  }
`;
