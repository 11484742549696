import { GET_USER, LOGIN_USER, LOGOUT_USER } from "./Actions";
import { User } from "../../../types/User";

export const getUserAction = (user: User) => ({
	type: GET_USER,
	payload: user,
});

export const loginUserAction = (token: string) => ({
	type: LOGIN_USER,
	payload: token,
});

export const logoutUserAction = () => ({
	type: LOGOUT_USER,
});
