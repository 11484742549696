import React, { forwardRef, useEffect } from "react";

// eslint-disable-next-line react/display-name
export const Checkbox = forwardRef(({ indeterminate, ...rest }: any, ref) => {
	const defaultRef = React.useRef();
	const resolvedRef = ref || defaultRef;

	useEffect(() => {
		// @ts-ignore
		resolvedRef.current.indeterminate = indeterminate;
	}, [resolvedRef, indeterminate]);

	return (
		<>
			<input
				className='check-mark-input'
				type='checkbox'
				ref={resolvedRef}
				{...rest}
			/>
		</>
	);
});
