import { House } from "../../../../types/House";
import {
	DEFAULT_EDITOR_SETTINGS,
	EditorSettings,
} from "../../../../types/EditorSettings";

export const getFillColor = (
	house: House,
	projectEditorSettings: EditorSettings
): string => {
	switch (house?.houseProperties.status) {
		case "Available":
			if (!projectEditorSettings.availableColor) {
				projectEditorSettings.availableColor =
					DEFAULT_EDITOR_SETTINGS.availableColor;
			}
			return projectEditorSettings.availableColor;
		case "Offer":
			if (!projectEditorSettings.offerColor) {
				projectEditorSettings.offerColor = DEFAULT_EDITOR_SETTINGS.offerColor;
			}
			return projectEditorSettings.offerColor;
		case "Sold":
			if (!projectEditorSettings.soldColor) {
				projectEditorSettings.soldColor = DEFAULT_EDITOR_SETTINGS.soldColor;
			}
			return projectEditorSettings.soldColor;
		default:
			if (!projectEditorSettings.fillColor) {
				projectEditorSettings.fillColor = DEFAULT_EDITOR_SETTINGS.fillColor;
			}
			return projectEditorSettings.fillColor;
	}
};

export const getFillOpacity = (
	house: House,
	projectEditorSettings: EditorSettings
): number => {
	switch (house?.houseProperties.status) {
		case "Available":
			return projectEditorSettings.availableOpacity;
		case "Offer":
			return projectEditorSettings.offerOpacity;
		case "Sold":
			return projectEditorSettings.soldOpacity;
		default:
			return projectEditorSettings.fillOpacity;
	}
};
