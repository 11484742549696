import React, { useState } from "react";
import { ColorResult, RGBColor, SketchPicker } from "react-color";
import { Dialog } from "@material-ui/core";
import PredefinedColors from "./PredefinedColors";

const CustomColorPicker = (props: {
	color: string;
	opacity: number;
	handleColorChange: (color: string) => void;
	handleOpacityChange?: (opacity: number) => void;
}) => {
	const { color, opacity, handleColorChange, handleOpacityChange } = props;

	const [colorPickerOpened, setColorPickerOpened] = useState<boolean>(false);

	const [currentColor, setCurrentColor] = useState<ColorResult>();

	const convertHexToColor = (colorHex: string): RGBColor => {
		const bigint = parseInt(colorHex.replaceAll("#", ""), 16);
		const r = (bigint >> 16) & 255;
		const g = (bigint >> 8) & 255;
		const b = bigint & 255;
		const a = colorHex.length > 7 ? bigint : opacity;
		return { r, g, b, a };
	};

	const renderColorSquareBackground = () => {
		if (opacity === 0) {
			return {
				content: "url(/no_fill.png)",
			};
		}
		return {
			backgroundColor: color,
			opacity: opacity,
			width: "30px",
			height: "30px",
		};
	};

	return (
		<div
			style={{
				marginLeft: "15px",
			}}
		>
			<div
				style={{
					background:
						opacity !== 0
							? "linear-gradient(90deg, rgba(255, 255, 255, .3) 50%, transparent 50%),linear-gradient(rgba(0, 0, 0, .3) 50%, transparent 50%)"
							: "none",
					border: "solid black 1px",
					borderRadius: "4px",
				}}
			>
				<div
					style={renderColorSquareBackground()}
					onClick={() => setColorPickerOpened(true)}
				></div>
			</div>
			<Dialog open={colorPickerOpened} onClose={() => setColorPickerOpened(false)}>
				<SketchPicker
					presetColors={[]}
					color={currentColor?.rgb || convertHexToColor(color)}
					onChange={color => setCurrentColor(color)}
					onChangeComplete={color => {
						handleColorChange(color.hex);
						handleOpacityChange && handleOpacityChange(color.rgb.a || 0);
					}}
				/>
				<PredefinedColors
					colors={[
						"#00000000",
						"#F5A623",
						"#F8E71C",
						"#8B572A",
						"#7ED321",
						"#417505",
						"#BD10E0",
						"#9013FE",
						"#4A90E2",
						"#50E3C2",
						"#B8E986",
						"#000000",
						"#4A4A4A",
						"#9B9B9B",
						"#FFFFFF",
					]}
					onClick={color => {
						const rgbColor = convertHexToColor(color);
						setCurrentColor({
							hex: color,
							hsl: {
								h: 0,
								l: 0,
								s: 0,
							},
							rgb: rgbColor,
						});
						handleColorChange(color);
						handleOpacityChange && handleOpacityChange(rgbColor.a || 0);
					}}
				/>
			</Dialog>
		</div>
	);
};

export default CustomColorPicker;
