import React from "react";
import HeroSection from "../HeroSection";
import Services from "../../components/Services";
import InfoSection from "../InfoSection";
import { homeObjOne, homeObjThree, homeObjTwo } from "../InfoSection/Data";

const Home = () => {
	return (
		<>
			<HeroSection />
			<Services />
			<InfoSection {...homeObjOne} />
			<InfoSection {...homeObjTwo} />
			<InfoSection {...homeObjThree} />
		</>
	);
};

export default Home;
