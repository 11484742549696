import cloneDeep from 'clone-deep';
import React, { useCallback, useContext, useState } from 'react';
import { HouseContext } from 'tool/components/context/HouseContext';
import { HouseChangeWrapper, SearchBar } from './styles';

const HouseChange = ({
  houses,
  savedPolygons,
  updateProjectData,
  project,
  XYcords,
  setMenuHouses,
  setIsContextMenuButtonClicked,
  enableWheel,
}: any) => {
  const [housesState, setHousesState] = useState<any>(houses);
  const { house, setHouse, polygonId } = useContext(HouseContext);

  const changeHouse = (houseId: any) => {
    const clonePolygons = cloneDeep(savedPolygons);
    const index = clonePolygons.findIndex((polygon: any) => polygon.id === polygonId);
    clonePolygons[index].houseId = houseId;
    const polygon = clonePolygons[index];

    const cloneProject = cloneDeep(project);
    const houseIndexToRemovePolygon = cloneProject.houses.findIndex((item: any) => item.id === house?.id);
    const polygonIndex = cloneProject.houses[houseIndexToRemovePolygon].polygons.findIndex(
      (polygon: any) => polygon.id === polygonId,
    );

    cloneProject.houses[houseIndexToRemovePolygon].polygons.splice(polygonIndex, 1);

    const houseIndexToPushPolygon = cloneProject.houses.findIndex((item: any) => item.id === houseId);
    cloneProject.houses[houseIndexToPushPolygon].polygons.push(polygon);

    updateProjectData(cloneProject);

    setHouse(cloneProject.houses[houseIndexToPushPolygon]);
    setMenuHouses(false);
    setIsContextMenuButtonClicked(false);
  };

  const disableContextMenu = (e: any) => {
    e.preventDefault();
  };

  const filterHouses = useCallback(
    (filter: string) => {
      const filteredHouses = houses.filter((h: any) => String(h.customId).includes(filter));
      setHousesState(filteredHouses);
    },
    [houses],
  );

  return (
    <HouseChangeWrapper
      onContextMenu={(e) => disableContextMenu(e)}
      id="menu"
      top={XYcords.y}
      left={XYcords.x}
      style={{ pointerEvents: enableWheel ? 'none' : 'auto' }}
    >
      <SearchBar
        id="searchBar-house"
        placeholder="Zoeken"
        onChange={(event) => filterHouses(event.target.value)}
      />
      <div>
        {housesState.map((h: any, index: number) => {
          return (
            <p
              key={h.id}
              id="menuItem"
              onClick={() => changeHouse(h.id)}
              style={house?.id === h.id ? { backgroundColor: '#508ef2' } : {}}
            >
              {h.customId}
            </p>
          );
        })}
      </div>
    </HouseChangeWrapper>
  );
};

export default HouseChange;
