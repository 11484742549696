import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { Redirect, useParams } from 'react-router-dom';
import { PROJECT_DRAW_PATH } from '../constants/PathConstants';
import { TOOL_VIEW_SET } from '../redux/actions/Actions';
import { useReduxFetch } from '../hooks/useReduxFetch';
import ProjectService from '../../services/ProjectService';

export const DrawingPage = (props: { projectId?: string }) => {
  const dispatch = useDispatch();

  const token = localStorage.getItem('AccessToken');

  const { id } = useParams<{ id: string }>();

  useEffect(() => {
    if (token && id) {
      dispatch(ProjectService.getProjectByPrimaryId(id, token));
    }
  }, []);

  dispatch({ type: TOOL_VIEW_SET, payload: true });
  return <Redirect to={PROJECT_DRAW_PATH + (props.projectId ? props.projectId : id)} />;
};
