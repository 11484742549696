import React, {
	forwardRef,
	useEffect,
	useImperativeHandle,
	useState,
} from "react";
import { FormInput, FormLabel } from "./FormElements";
import {
	validateEmail,
	validateLength,
	validateNotEmpty,
} from "../../utils/validation/InputValidation";

// eslint-disable-next-line react/display-name
const LabeledInput = forwardRef(
	(
		props: {
			placeholder?: string;
			labelText?: string;
			value?: string | number;
			onChange: (e: any) => void;
			block?: boolean;
			type?: string;
			notRequired?: boolean;
			email?: boolean;
			minLength?: number;
			maxLength?: number;
			setErrorMessage?: (message: string) => void;
			labelLeft?: boolean;
			className?: string;
			labelClass?: string;
			errorText?: string;
		},
		ref
	) => {
		const {
			labelText,
			value,
			onChange,
			type,
			notRequired,
			email,
			minLength,
			maxLength,
			labelLeft,
			className,
			placeholder,
			labelClass,
			errorText,
		} = props;

		const [validateErrorMessage, setValidateErrorMessage] = useState<
			string | undefined
		>();

		useEffect(() => {
			if (!notRequired) {
				props.setErrorMessage && props.setErrorMessage(validateNotEmpty(value));
			}
			// if (email) {
			// 	props.setErrorMessage &&
			// 		props.setErrorMessage(validateEmail(value as string));
			// }
			if (minLength || maxLength) {
				props.setErrorMessage &&
					props.setErrorMessage(validateLength(value, minLength, maxLength));
			}
		}, []);

		const validate = () => {
			if (!notRequired) {
				setValidateErrorMessage(validateNotEmpty(value));
				props.setErrorMessage && props.setErrorMessage(validateNotEmpty(value));
			}
			if (email) {
				// setValidateErrorMessage(validateEmail(value as string));
				props.setErrorMessage &&
					props.setErrorMessage(validateEmail(value as string));
			}
			if (minLength || maxLength) {
				setValidateErrorMessage(validateLength(value, minLength, maxLength));
				props.setErrorMessage &&
					props.setErrorMessage(validateLength(value, minLength, maxLength));
			}
		};

		useImperativeHandle(ref, () => ({
			validate() {
				validate();
			},
		}));

		return (
			<div
				style={{
					display: "flex",
					flexDirection: props.labelLeft ? "row" : "column",
					justifyContent: "flex-end",
					marginBottom: "32px",
					position: "relative",
				}}
			>
				{labelText && <FormLabel className={labelClass}>{labelText}</FormLabel>}
				<FormInput
					onBlur={validate}
					value={value}
					onChange={onChange}
					type={type}
					required={!notRequired}
					validationError={
						validateErrorMessage ? validateErrorMessage.length >= 0 : false
					}
					className={className}
					placeholder={placeholder}
				/>
				<div
					style={{
						color: "red",
						fontFamily: "Silka",
						position: "absolute",
						bottom: -20,
					}}
				>
					{validateErrorMessage}
				</div>
			</div>
		);
	}
);
export default LabeledInput;
