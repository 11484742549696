import React, { useRef, useState } from 'react';
import LabeledInput from '../../../components/form/LabeledInput';
import { useTranslation } from 'react-i18next';
import translationEN from '../../../configs/i18n/locales/en/translation.json';
import { Form, FormButton, FormContent, FormH1, FormWrap } from '../../../components/form/FormElements';
import { Container } from '../../../components/layouts/LayoutElements';
import { areAllFieldsValid } from '../../../utils/validation/InputValidation';
import { Link as LinkR } from 'react-router-dom';
import { LOGIN_PATH } from '../../../constants/PathConstants';
import CheckBox from '../../../components/checkboxes';

const RegisterForm = (props: { onSubmit: (username: string, email: string, password: string) => void }) => {
  const { t } = useTranslation();

  const usernameRef = useRef();
  const emailRef = useRef();
  const passwordRef = useRef();
  const companyRef = useRef();
  const refs = [usernameRef, emailRef, passwordRef];

  const [username, setUsername] = useState<string>('');
  const [email, setEmail] = useState<string>('');
  const [password, setPassword] = useState<string>('');
  // const [company, setCompany] = useState<string>("");
  const [agreedTermsOfService, setAgreedTermsOfService] = useState<boolean>(false);
  const [agreedPrivacyPolicy, setAgreedPrivacyPolicy] = useState<boolean>(false);
  const [agreedTermsOfServiceError, setAgreedTermsOfServiceError] = useState<boolean>(false);
  const [agreedPrivacyPolicyError, setAgreedPrivacyPolicyError] = useState<boolean>(false);
  const [usernameError, setUsernameError] = useState<string>('');
  const [emailError, setEmailError] = useState<string>('');
  const [passwordError, setPasswordError] = useState<string>('');
  const [companyError, setCompanyError] = useState<string>('');

  return (
    <>
      <Container>
        <FormWrap>
          <FormContent>
            <Form>
              <FormH1>Create a new account</FormH1>
              <LabeledInput
                ref={usernameRef}
                labelText={t(translationEN.username)}
                value={username}
                onChange={(e) => setUsername(e.target.value)}
                minLength={3}
                maxLength={15}
                setErrorMessage={setUsernameError}
                labelLeft={true}
                errorText="regErrorText"
              />
              <LabeledInput
                ref={emailRef}
                labelText={t(translationEN.email)}
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                type="email"
                email
                setErrorMessage={setEmailError}
                labelLeft={true}
                errorText="regErrorText"
              />
              <LabeledInput
                ref={passwordRef}
                labelText={t(translationEN.password)}
                value={password}
                onChange={(e) => setPassword(e.target.value)}
                type="password"
                minLength={1}
                setErrorMessage={setPasswordError}
                labelLeft={true}
                errorText="regErrorText"
              />
              {/* <LabeledInput
								ref={companyRef}
								labelText={t(translationEN.company)}
								value={company}
								onChange={e => setCompany(e.target.value)}
								labelLeft={true}
								minLength={5}
								setErrorMessage={setCompanyError}
								errorText='regErrorText'
							/> */}
              <div
                style={{
                  display: 'flex',
                  alignItems: 'center',
                  marginBottom: '15px',
                  marginLeft: '25px',
                  fontFamily: 'Galano Grotesque',
                  position: 'relative',
                }}
              >
                <CheckBox checked={agreedTermsOfService} setChecked={setAgreedTermsOfService} />
                {t(translationEN['I have read and agreed to the Map Tool Terms of Service'])}
                {agreedTermsOfServiceError && (
                  <div
                    style={{
                      color: 'red',
                      fontFamily: 'Silka',
                      position: 'absolute',
                      right: '-200px',
                    }}
                  >
                    This field is required
                  </div>
                )}
              </div>
              <div
                style={{
                  display: 'flex',
                  alignItems: 'center',
                  marginLeft: '25px',
                  marginBottom: '15px',
                  fontFamily: 'Galano Grotesque',
                  position: 'relative',
                }}
              >
                <CheckBox checked={agreedPrivacyPolicy} setChecked={setAgreedPrivacyPolicy} />
                {t(translationEN['I have read and agreed to the Map Tool Privacy Policy'])}
                {agreedPrivacyPolicyError && (
                  <div
                    style={{
                      color: 'red',
                      fontFamily: 'Silka',
                      position: 'absolute',
                      right: '-200px',
                    }}
                  >
                    This field is required
                  </div>
                )}
              </div>
              <div
                style={{
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                  marginBottom: '20px',
                  marginTop: '30px',
                  fontFamily: 'Galano Grotesque',
                }}
              >
                Already have an account
                <LinkR
                  style={{
                    color: '#3392FF',
                    marginLeft: '8px',
                    fontFamily: 'Galano Grotesque',
                  }}
                  to={LOGIN_PATH}
                >
                  {' '}
                  Log in
                </LinkR>
              </div>
              <FormButton
                onClick={() => {
                  setAgreedTermsOfServiceError(!agreedTermsOfService);
                  setAgreedPrivacyPolicyError(!agreedPrivacyPolicy);
                  // @ts-ignore
                  refs.forEach((ref) => ref.current.validate());
                  if (!agreedTermsOfService || !agreedPrivacyPolicy) {
                    return;
                  }

                  if (areAllFieldsValid(usernameError, passwordError)) {
                    props.onSubmit(username, email, password);
                  }
                }}
              >
                Sign up
              </FormButton>
            </Form>
          </FormContent>
        </FormWrap>
      </Container>
    </>
  );
};
export default RegisterForm;
