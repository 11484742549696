import React, { useContext } from "react";
import styled from "styled-components";
import { AppMode, AppModeContext } from "../../../context/AppModeContext";
import {
	calculateCanvasIntersectionPoints,
	calculateGuidelineControls,
	calculateGuideLineHiddenScalingPolygon,
} from "../utils/MapBuildUtils";
import { ViewBoxScale } from "../Canvas";
import { GuideLineType } from "../../../../../types/GuideLineType";
import { Point } from "../../../../../types/PolygonType";

export function GuideLine(props: {
	guideLine: GuideLineType;
	selectedGuideLineId: string | undefined | null;
	viewBoxScale: ViewBoxScale;
	currentScale: number;
	isDrawingGuideline: boolean;
	isDrawingPath?: boolean;
}) {
	const {
		guideLine,
		selectedGuideLineId,
		viewBoxScale,
		currentScale,
		isDrawingGuideline,
		isDrawingPath,
	} = props;
	const { appMode } = useContext(AppModeContext);
	const isSelected = guideLine.id === selectedGuideLineId;
	const stroke = guideLine.style?.color
		? guideLine.style?.color
		: isSelected
		? "#3392FF"
		: "red";
	const adjustmentPoints = calculateGuidelineControls(
		guideLine.linePoints.point1!,
		guideLine.linePoints.point2!
	);
	const [intersectionPoint1, intersectionPoint2]: Point[] | null[] =
		isDrawingGuideline
			? calculateCanvasIntersectionPoints(guideLine, viewBoxScale)
			: [null, null];

	guideLine.adjustmentPoints = {
		point1: adjustmentPoints.point2,
		point2: adjustmentPoints.point1,
	};

	if (appMode === AppMode.view) {
		return null;
	} else {
		return (
			<g>
				<g id={guideLine.id!.toString()}>
					{isDrawingGuideline && intersectionPoint1 && intersectionPoint2 && (
						<>
							<SVGLine
								stroke={stroke}
								strokeWidth={guideLine.style?.width || "0.25pt"}
								x1={intersectionPoint1.x}
								y1={intersectionPoint1.y}
								x2={guideLine.linePoints.point1?.x}
								y2={guideLine.linePoints.point1?.y}
								currentScale={currentScale}
							/>
							<SVGLine
								stroke={stroke}
								strokeWidth={guideLine.style?.width || "0.25pt"}
								x1={guideLine.linePoints.point2?.x}
								y1={guideLine.linePoints.point2?.y}
								x2={intersectionPoint2.x}
								y2={intersectionPoint2.y}
								currentScale={currentScale}
							/>
						</>
					)}
					<SVGLine
						stroke={stroke}
						strokeWidth={guideLine.style?.width || "0.25pt"}
						x1={guideLine.linePoints.point1?.x}
						y1={guideLine.linePoints.point1?.y}
						x2={guideLine.linePoints.point2?.x}
						y2={guideLine.linePoints.point2?.y}
						currentScale={currentScale}
					/>
				</g>
				{guideLine.polygonPoints ? (
					<SVGPolygon
						id={guideLine.id!.toString()}
						points={Array.from(
							calculateGuideLineHiddenScalingPolygon(
								guideLine,
								viewBoxScale,
								currentScale
							)
						).reduce((str, point) => str + " " + point!.x + "," + point!.y, "")}
						currentScale={currentScale}
					/>
				) : null}
				{guideLine.linePoints.point1 &&
					guideLine.linePoints.point2 &&
					isSelected && (
						<>
							<SVGCircle
								id={"move;" + guideLine.id!.toString()}
								cx={
									(guideLine.linePoints.point1.x +
										guideLine.linePoints.point2.x) /
									2
								}
								cy={
									(guideLine.linePoints.point1.y +
										guideLine.linePoints.point2.y) /
									2
								}
								style={{ cursor: "move" }}
								currentScale={currentScale}
							/>
							<SVGCircle
								id={"point1;" + guideLine.id!.toString()}
								cx={guideLine.adjustmentPoints?.point1.x}
								cy={guideLine.adjustmentPoints?.point1.y}
								style={{
									stroke: "yellow",
									fill: "white",
									cursor: "nesw-resize",
								}}
								currentScale={currentScale}
							/>
							<SVGCircle
								id={"point2;" + guideLine.id!.toString()}
								cx={guideLine.adjustmentPoints?.point2.x}
								cy={guideLine.adjustmentPoints?.point2.y}
								style={{
									stroke: "yellow",
									fill: "white",
									cursor: "nesw-resize",
								}}
								currentScale={currentScale}
							/>
						</>
					)}
				{guideLine.intersectionPoints &&
					isDrawingPath &&
					Array.from(guideLine.intersectionPoints).map(
						(point: Point, index: number) => (
							<SVGIntersectionCircle
								key={index}
								cx={point.x}
								cy={point.y}
								currentScale={currentScale}
							/>
						)
					)}
			</g>
		);
	}
}

const SVGLine = styled.line<{ currentScale: number }>`
	stroke-width: ${props => 0.25 / props.currentScale}pt;
`;

const SVGPolygon = styled.polygon<{ currentScale: number }>`
	stroke: white;
	stroke-width: ${props => 1 / props.currentScale}pt;
	stroke-opacity: 0;
	fill-opacity: 0;
`;

const SVGIntersectionCircle = styled.circle<{ currentScale: number }>`
	fill: white;
	fill-opacity: 0;
	stroke: purple;
	stroke-width: ${props => 2 / props.currentScale}pt;
	stroke-opacity: 0;
	r: ${props => 2 / props.currentScale}pt;

	&:hover {
		fill-opacity: 1;
		stroke-opacity: 1;

		r: ${props => 2 / props.currentScale}pt;
	}
`;

const SVGCircle = styled.circle<{ currentScale: number }>`
	fill: white;
	r: ${props => 1.5 / props.currentScale}pt;
	stroke: red;
	stroke-width: ${props => 1 / props.currentScale}pt;
	stroke-dasharray: 0;
	stroke-linejoin: round;
`;
