import React, { useCallback, useContext, useEffect, useState } from 'react';
import './ProjectSettings.css';
import { AriaViewUploaderComponent } from '../AriaViewUploaderComponent';
import { createStyles } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { MapBuild } from './map/MapBuild';
import ConfirmationPopUp from '../ConfirmationPopUp';
import { ToolBoxComponent } from './toolbox/ToolBoxComponent';
import { HouseContext } from '../context/HouseContext';
import { ToolboxContext } from '../context/ToolboxContext';
import { AppMode, AppModeContext } from '../context/AppModeContext';
import { Project } from '../../../types/Project';
import { PolygonType } from '../../../types/PolygonType';
import { GuideLineType } from '../../../types/GuideLineType';
import { DEFAULT_EDITOR_SETTINGS } from '../../../types/EditorSettings';
import { ToolProps } from '../Tool';
import { ExportSvg } from './toolbox/ExportSvg';
import cloneDeep from 'clone-deep';
import { INITIAL_VIEW_BOX_X_VALUE, INITIAL_VIEW_BOX_Y_VALUE } from '../constants/constants';
import { ViewBoxScale } from './map/Canvas';
import { renderToStaticMarkup } from 'react-dom/server';
import JSZip from 'jszip';
import { useDrop, XYCoord } from 'react-dnd';
import update from 'immutability-helper';
import { DragItem } from '../../../types/DragItem';
import SvgService from '../../../services/SvgService';
import { toast } from 'react-toastify';
import { House } from '../../../types/House';

const useStyles = makeStyles(() =>
  createStyles({
    addButton: {
      marginLeft: '15px',
      marginRight: '1.5%',
      marginTop: '0px',
      alignSelf: 'flex-start',
      display: 'block',
    },
    details: {
      alignItems: 'center',
    },
    housesBlock: {
      width: '30%',
      display: 'flex',
      alignItems: 'center',
      flexDirection: 'column',
      marginLeft: '10px',
      marginTop: '15px',
    },
    mapAndToolBoxBlock: {
      width: '100%',
      display: 'flex',
      flexDirection: 'row-reverse',
      paddingTop: '0',
      position: 'relative',
    },
    imageDiv: {
      width: '100%',
      paddingLeft: '12px',
    },
    imageDivToolBoxShown: {
      paddingLeft: '0',
    },
    topButtonBlock: {
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'space-between',
    },
    topSticky: {
      position: 'fixed',
      zIndex: 99,
      right: 0,
      backgroundColor: '#201C1D',
      height: '100vh',
      overflow: 'scroll',
    },
    toolBoxSticky: {
      position: 'fixed',
      top: 134,
      left: 200,
      height: 0,
      paddingLeft: '0px',
      zIndex: 99,
      width: 0,
    },
  }),
);

export const ToolPanel = ({
  project,
  updateProjectData,
  appMode,
  publishButtons,
  stepMode,
  setToolView,
  tableButton,
  fullMode,
  isDrawingPage,
  handleUndo,
  handleRedo,
}: ToolProps) => {
  const classes = useStyles();
  const [stateProject, setStateProject] = useState<Project | null>(project);

  const [savedPolygons, setSavedPolygons] = useState<any[]>([]);
  const [savedGuideLines, setSavedGuideLines] = useState<any[]>([]);
  const [addHouseDialogOpen, setAddHouseDialogOpen] = useState(false);
  const [imageLoadUrl, setImageLoadUrl] = useState('');
  const [selectedPlotId, setSelectedPlotId] = useState<string | null>(null);
  const [isAlertPolygonShown, setIsAlertPolygonShown] = useState(false);
  const [isUndoHistoryPresent, setIsUndoHistoryPresent] = useState(false);
  const [isRedoHistoryPresent, setIsRedoHistoryPresent] = useState(false);
  const [houses, setHouses] = useState<any>([]);

  const { house, setHouse, setNewPlot, polygonId, setPolygonId } = useContext(HouseContext);
  const { setAppMode } = useContext(AppModeContext);
  const {
    isDrawingButtonClicked,
    setIsDrawingButtonClicked,
    isEditButtonClicked,
    setIsEditButtonClicked,
    setIsSavePolygonButtonClicked,
    isAddInnerLineButtonClicked,
    setIsAddInnerLineButtonClicked,
    toolBoxShown,
    setIsPointerButtonClicked,
    isDrawGuideLineButtonClicked,
  } = useContext(ToolboxContext);

  const [toolDnd, setToolDnd] = useState<{
    [key: string]: {
      top: number;
      left: number;
    };
  }>({
    tool: { top: 100, left: 100 },
  });

  useEffect(() => {
    setAppMode(appMode || AppMode.edit);
  }, []);

  useEffect(() => {
    if (project) {
      if (imageLoadUrl !== '') {
        setAerialViewImage(imageLoadUrl);
      }
      updateProjectData &&
        updateProjectData({
          ...project,
          aerialViewImage: imageLoadUrl,
        });
    }
  }, [imageLoadUrl]);

  useEffect(() => {
    if (project) {
      setStateProject(project);

      const sorted = project.houses;
      sorted.sort(
        (a: House, b: House) =>
          extractId(a.houseProperties.customId.toString()) - extractId(b.houseProperties.customId.toString()),
      );

      setHouses(sorted);
    }
  }, [project]);

  useEffect(() => {
    setAppMode(appMode || AppMode.edit);
    if (appMode === AppMode.view) {
      setIsDrawingButtonClicked(false);
      setIsEditButtonClicked(false);
    }
  }, [appMode]);

  const extractId = (customId: string): number => {
    let idEndPosition = 0;
    for (let i = customId.length - 1; i >= 0; i--) {
      const char = +customId.charAt(i);
      if (!isNaN(char)) {
        idEndPosition = i + 1;
        break;
      }
    }

    const id = customId.toString().substring(0, idEndPosition);
    return +id;
  };

  const updateSavedPolygons = (project: Project) => {
    const houses = project?.houses;
    if (houses) {
      let polygons: PolygonType[] = [];
      for (const house of houses) {
        if (house.polygons) {
          polygons = [...polygons, ...house.polygons];
        }
      }
      setSavedPolygons(polygons);
    }
  };

  useEffect(() => {
    if (!isDrawingButtonClicked && !isDrawGuideLineButtonClicked) {
      setIsPointerButtonClicked(true);
      setPolygonId(null);
      setSelectedPlotId(null);
    } else {
      setIsPointerButtonClicked(false);
    }
  }, [isDrawingButtonClicked, isDrawGuideLineButtonClicked]);

  useEffect(() => {
    if (stateProject) {
      updateSavedPolygons(stateProject);
    }

    if (stateProject?.projectProperties?.guideLines) {
      setSavedGuideLines(stateProject.projectProperties.guideLines);
    }
  }, [stateProject]);

  if (!stateProject) {
    return (
      <ConfirmationPopUp
        actionPopupButtonLabel={'Ok'}
        customClosePopUpButton={null}
        isOpen={true}
        closePopUp={() => {}}
        confirmationLabel={'Project does not exists'}
        confirmationText={
          <>
            Click <b>Ok</b> button to return to projects list
          </>
        }
        action={() => {
          setToolView && setToolView(false);
        }}
      />
    );
  }

  const [aerialViewImage, setAerialViewImage] = useState(stateProject.aerialViewImage);

  // const { aerialViewImage } = stateProject;

  const handleAlertClose = () => {
    setIsAlertPolygonShown(false);
  };

  const checkPolygonPointsAmount = (polygons: PolygonType[]): boolean => {
    for (const polygon of polygons) {
      if (polygon.points.length < 3) {
        return true;
      }
    }
    return false;
  };

  function savePolygons(polygons: PolygonType[]) {
    if (polygons.length === 0) {
      return;
    }
    const houseToSave = stateProject?.houses?.find((statePolygonHouse) => statePolygonHouse.id === house!.id);

    if (!houseToSave) {
      return;
    }

    houseToSave.polygons = polygons.filter((polygon) => polygon.houseId === houseToSave.id);
    if (houseToSave.polygons.length === 0 || checkPolygonPointsAmount(houseToSave.polygons)) {
      setIsAlertPolygonShown(true);
    } else {
      setSavedPolygons(polygons);
      setIsSavePolygonButtonClicked(false);
      updateProjectData && updateProjectData(project);
    }
  }

  const handleAddHouse = () => {
    setAddHouseDialogOpen(true);
  };

  const handleAddHouseDialogClose = () => {
    setAddHouseDialogOpen(false);
  };

  const handleDrawPolygon = () => {
    if (!house) {
      setHouse(stateProject.houses[0]);
    }
    // else if(!house && houseNumber > 0) {
    // 	setHouse(stateProject.houses[houseNumber])
    // }

    // if(houseNumber < stateProject.houses.length - 1) {
    // 	console.log()
    // 	setHouseNumber(prev => prev + 1)
    // }

    setPolygonId(null);
    // selectPlot(null);
    setSelectedPlotId(null);
    // if (house.polygons.length === 0) {
    setIsDrawingButtonClicked(!isDrawingButtonClicked);
    // }
  };

  const handleEditPolygon = () => {
    if (!house) {
      return;
    }

    if (isEditButtonClicked) {
      setIsEditButtonClicked(false);
    } else {
      setIsEditButtonClicked(true);
    }
  };

  const handleDrawInnerLine = () => {
    setIsAddInnerLineButtonClicked(!isAddInnerLineButtonClicked);
  };

  const handleDeletePolygon = async () => {
    if (!house) {
      return;
    }

    const findHouse = stateProject!.houses!.find((statePolygonHouse) => statePolygonHouse.id === house.id);

    if (findHouse && findHouse.polygons.length > 0 && polygonId) {
      const deletedIndex = findHouse.polygons.findIndex((polygon) => polygon.id === polygonId);

      const index = savedPolygons.findIndex((polygon) => polygon.id === polygonId);
      setSavedPolygons([...savedPolygons.splice(index, 1)]);
      findHouse.polygons.splice(deletedIndex, 1);

      const findHouseinProject = stateProject.houses.findIndex((house) => house.id === findHouse.id);
      const newProject = cloneDeep(stateProject);
      newProject.houses[findHouseinProject].polygons.splice(deletedIndex, 1);
      setStateProject({ ...newProject });

      updateProjectData && updateProjectData(stateProject);
    }
    setPolygonId(null);
    setNewPlot(null);
    setIsEditButtonClicked(false);
  };

  function handleSaveGuideLine(line: GuideLineType, moving?: boolean) {
    const lineToSave = { ...line };
    if (stateProject && stateProject.id) {
      const stateProjectCopy = cloneDeep(stateProject);
      const guideLines = stateProjectCopy?.projectProperties?.guideLines;
      if (moving) {
        if (guideLines) {
          const lineToDeleteIndex = guideLines.findIndex((line: any) => line.id === lineToSave.id);
          guideLines.splice(lineToDeleteIndex, 1);
        }
      }
      if (stateProjectCopy?.projectProperties?.guideLines) {
        stateProjectCopy.projectProperties.guideLines = [
          ...stateProjectCopy.projectProperties.guideLines,
          lineToSave,
        ];
      } else if (!stateProjectCopy.projectProperties) {
        stateProjectCopy.projectProperties = {
          guideLines: [lineToSave],
        };
      }
      updateProjectData && updateProjectData({ ...stateProjectCopy });
    }
  }

  function handleDeleteGuideLine(guideLine: GuideLineType) {
    if (stateProject && stateProject.projectProperties?.guideLines) {
      const guideLines: GuideLineType[] = [...stateProject.projectProperties.guideLines];
      const lineIndex = guideLines.findIndex((line) => line.id === guideLine.id);
      guideLines.splice(lineIndex, 1);
      stateProject.projectProperties.guideLines = guideLines;

      const updatedProject = { ...stateProject, guideLines };
      updateProjectData && updateProjectData(updatedProject);
    }
  }

  const selectPlot = (plotId: string | null) => {
    if (plotId) {
      setSelectedPlotId(plotId);
      if (plotId.startsWith('polygon')) {
        const polygon: PolygonType = savedPolygons.find((polygon) => polygon.id === plotId);
        const house = stateProject.houses?.find((h) => {
          if (polygon) {
            return polygon.houseId === h.id;
          }
          return false;
        });
        if (house) {
          setHouse(house);
        }
      }
    } else {
      setSelectedPlotId(null);
      setHouse(null!);
      setIsDrawingButtonClicked(false);
    }
  };

  const exportZip = (html: string, svgName: string, imageName: string, imageBlob: Blob) => {
    const zip = new JSZip();
    zip.file(svgName, html);
    zip.file(imageName, imageBlob);
    zip.generateAsync({ type: 'base64' }).then(function (content) {
      const link = document.createElement('a');
      link.href = 'data:application/zip;base64,' + content;
      link.download = project ? project.name + '.zip' : 'export.zip';
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    });
  };

  const handleExport = () => {
    if (aerialViewImage) {
      let imageName: string = project ? project.name : 'image';
      const svgName: string = project ? project.name + '.svg' : 'export.svg';
      fetch(aerialViewImage)
        .then((res) => res.blob()) // Gets the response and returns it as a blob
        .then((imageBlob) => {
          if (imageBlob.type === 'image/jpeg') {
            imageName += '.jpg';
          } else if (imageBlob.type === 'image/png') {
            imageName += '.png';
          }

          const img = new Image();
          img.src = aerialViewImage;

          let viewBoxScale: ViewBoxScale = {
            point1: { x: 0, y: 0 },
            point2: { x: INITIAL_VIEW_BOX_X_VALUE, y: INITIAL_VIEW_BOX_Y_VALUE },
          };

          img.onload = () => {
            const y = (viewBoxScale.point2.x / img.width) * img.height;
            viewBoxScale = {
              point1: { x: 0, y: 0 },
              point2: { x: INITIAL_VIEW_BOX_X_VALUE, y: y },
            };

            const exportSvg = (
              <ExportSvg
                houses={stateProject.houses}
                aerialView={isDrawingPage ? aerialViewImage : imageName}
                viewBoxScale={viewBoxScale}
              />
            );
            let html = renderToStaticMarkup(exportSvg);
            html = html.replaceAll('></polygon>', ' class="polygonStyle"></polygon>');
            const formatter = require('html-formatter');
            html = formatter.render(html);

            !isDrawingPage
              ? exportZip(html, svgName, imageName, imageBlob)
              : saveSvg(html, project.primaryId);
          };
        });
    }
  };

  const saveSvg = (html: string, projectId: string | undefined) => {
    SvgService.saveSvg(html, projectId);
    toast.success('SVG uploaded');
  };

  const moveBox = useCallback(
    (id: string, left: number, top: number) => {
      setToolDnd(
        update(toolDnd, {
          [id]: {
            $merge: { left, top },
          },
        }),
      );
    },
    [toolDnd, setToolDnd],
  );

  const [, drop] = useDrop(
    () => ({
      accept: 'box',
      drop(item: DragItem, monitor) {
        const delta = monitor.getDifferenceFromInitialOffset() as XYCoord;
        const left = Math.round(item.left + delta.x);
        const top = Math.round(item.top + delta.y);
        moveBox(item.id, left, top);
        return undefined;
      },
    }),
    [moveBox],
  );
  const [menuHouseIdChange, setMenuHouseIdChange] = useState<boolean>(false);
  const [XY, setXY] = useState({
    x: 0,
    y: 0,
  });

  const changeHouseForPolygon = (polygonId?: any, x?: any, y?: any) => {
    setXY({ x, y });
    setMenuHouseIdChange(true);
  };

  const image = aerialViewImage ? (
    <MapBuild
      handleUndoPolygon={handleUndo}
      handleRedoPolygon={handleRedo}
      isDrawingPage={isDrawingPage}
      aerialView={aerialViewImage}
      savedPolygons={savedPolygons}
      savePolygons={savePolygons}
      selectedPlot={selectedPlotId}
      setSelectedPlot={selectPlot}
      isAlertPolygonShown={isAlertPolygonShown}
      showPolygonAlert={setIsAlertPolygonShown}
      handleAlertPolygonClose={handleAlertClose}
      savedGuideLines={savedGuideLines}
      saveGuideLine={handleSaveGuideLine}
      deleteGuideLine={handleDeleteGuideLine}
      houses={houses}
      setIsRedoHistoryPresent={setIsRedoHistoryPresent}
      setIsUndoHistoryPresent={setIsUndoHistoryPresent}
      projectEditorSettings={{
        ...(stateProject?.projectProperties?.editorSettings || DEFAULT_EDITOR_SETTINGS),
      }}
      handleExport={handleExport}
      publishButtons={publishButtons}
      stepMode={stepMode}
      projectId={stateProject.id}
      fullMode={fullMode}
      changeHouseForPolygon={changeHouseForPolygon}
      updateProjectData={updateProjectData}
      project={project}
      deletePolygon={handleDeletePolygon}
    />
  ) : (
    <div style={{ width: '25%', paddingLeft: '50%', paddingTop: '50px' }}>
      <AriaViewUploaderComponent setImageLoadUrl={setImageLoadUrl} />
    </div>
  );

  return (
    <div
      className="ProjectSettings"
      style={{
        display: 'flex',
        flexDirection: 'row-reverse',
        width: '100%',
        height: '100vh',
      }}
    >
      {/* ToolBox */}
      <div
        ref={drop}
        className="ProjectSettings"
        style={{
          width: '100%',
          height: '100vh',
          position: 'relative',
          display: 'flex',
          flexDirection: 'row-reverse',
          backgroundColor: 'black',
          overflow: 'scroll',
        }}
      >
        <div className={`${classes.imageDiv} ${toolBoxShown ? classes.imageDivToolBoxShown : ''}`}>
          {image}
        </div>
        {Object.keys(toolDnd).map((key) => {
          const { left, top } = toolDnd[key];
          return (
            <ToolBoxComponent
              left={left}
              top={top}
              id={key}
              key={key}
              houses={stateProject.houses ? stateProject.houses : []}
              editorSettings={
                stateProject?.projectProperties?.editorSettings
                  ? stateProject.projectProperties.editorSettings
                  : { ...DEFAULT_EDITOR_SETTINGS }
              }
              updateEditorSettings={(editorSettings) =>
                setStateProject({
                  ...stateProject,
                  projectProperties: {
                    ...stateProject?.projectProperties,
                    editorSettings,
                  },
                })
              }
              drawInnerLine={handleDrawInnerLine}
              isRedoHistory={isRedoHistoryPresent}
              isUndoHistory={isUndoHistoryPresent}
              handleAddHouse={handleAddHouse}
              handleReturnToProjectsList={() => setToolView && setToolView(false)}
              updateProjectData={(editorSettings) => {
                updateProjectData &&
                  updateProjectData({
                    ...project,
                    projectProperties: {
                      ...stateProject?.projectProperties,
                      editorSettings,
                    },
                  });
              }}
              tableButton={tableButton}
              deletePolygon={handleDeletePolygon}
              editPolygon={handleEditPolygon}
              drawPolygon={handleDrawPolygon}
              handleUndo={handleUndo}
              handleRedo={handleRedo}
              polygonId={polygonId}
              setSelectedPlotId={setSelectedPlotId}
              setImageLoadUrl={setImageLoadUrl}
            />
          );
        })}
      </div>
    </div>
  );
};
