import { AnyAction } from 'redux';
import { UserState } from 'types/state';
import { GET_USER, LOGIN_USER, LOGOUT_USER } from '../actions/Actions';

const initState: UserState = {
  user: null,
};

export const userReducer = (state = initState, action: AnyAction) => {
  switch (action.type) {
    case LOGIN_USER:
      return {
        ...state,
        user: action.payload,
      };
    case GET_USER:
      return {
        ...state,
        user: action.payload,
      };
    case LOGOUT_USER:
      return {
        ...state,
        user: null,
      };
    default:
      return {
        ...state,
      };
  }
};
