import React from "react";
import styled from "styled-components";

const InfoContainer = styled.div`
	width: 27%;
	display: flex;
	flex-direction: column;
	height: 70%;
	padding-left: 58px;
	border-right: 1px solid #707070;
`;

const Title = styled.text`
	font-family: Silka, serif;
	font-size: 1.37rem;
	font-weight: 500;
	margin-bottom: 53px;
`;

const Info = styled.text`
	font-family: Silka, serif;
	font-size: 1rem;
`;

export const ProjectInfo = (props: { projectId?: string; houses?: number }) => {
	return (
		<InfoContainer>
			<Title>Woonmodule</Title>
			<Info>
				ProjectId: {props.projectId}
				<br />
				{props.houses} houses
			</Info>
		</InfoContainer>
	);
};
