import React from "react";
import { Box, Button } from "@material-ui/core";

const ProjectToggle = ({ isProjectView, setIsProjectView }: any) => {
	return (
		<Box display={"flex"} justifyContent={"space-between"}>
			<Box>
				<Button
					style={{
						...(!isProjectView
							? { backgroundColor: "#007bff", color: "white" }
							: { backgroundColor: "white", color: "black" }),
						boxShadow: "0 3px 6px #000000",
						marginRight: 10,
					}}
					variant='contained'
					onClick={() => setIsProjectView(false)}
					disableElevation
				>
					Types
				</Button>
				<Button
					style={{
						...(isProjectView
							? { backgroundColor: "#007bff", color: "white" }
							: { backgroundColor: "white", color: "black" }),
						boxShadow: "0 3px 6px #000000",
					}}
					variant='contained'
					onClick={() => setIsProjectView(true)}
					disableElevation
				>
					Houses
				</Button>
			</Box>
		</Box>
	);
};

export default ProjectToggle;
