import styled from "styled-components";

export const Wrapper = styled.div`
	position: relative;

	input {
		width: 315px;
		height: 54px;
		border: none;
		padding: 0 40px;
		font-size: 16px;
		border: 1px solid transparent;
		transition: all 0.23s;

		&:hover,
		&:focus-visible {
			outline: none;
			border: 1px solid gray;
		}

		&::placeholder {
			color: #a7a7a7;
		}
	}

	.search {
		position: absolute;
		top: 50%;
		left: 10px;
		font-size: 25px;
		transform: translate(0, -50%);
	}
`;
