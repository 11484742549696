import styled from 'styled-components';

export const CreateProjectWrapper = styled.div`
  background-color: #dbdbdb;
  width: 100%;
  margin: 0 auto;
  margin-top: 100px;
  padding: 30px 20px 30px 40px;
`;

export const Container = styled.div`
  width: 100%;
  margin: 0 auto;
`;
export const Wrapper = styled.div``;

export const Title = styled.h1`
  font-size: 34px;
  padding-bottom: 30px;
`;

export const ProjectInfo = styled.div`
  display: flex;
`;

export const InfoWrapper = styled.div`
  padding-right: 50px;

  p {
    padding-bottom: 10px;
  }

  input {
    height: 54px;
    border: none;
    padding: 0 10px;
    font-size: 16px;
    border: 1px solid transparent;
    transition: all .23s;

    &:hover, &:focus-visible {
      outline: none;
      border: 1px solid gray;
    }

    &::placeholder {
      color: #A7A7A7;
    }
  }
}

#number-of-units {
  width: 315px;
}

#project-name {
  width: 163px;
}
`;

export const UploadImageWrapper = styled.div`
  display: flex;
  padding-left: 15px;
  align-items: center;

  h3 {
    font-size: 15px;
    width: 330px;
    margin-left: 15px;
  }

  div {
    text-align: center;

    p {
      padding-bottom: 10px;
    }

    #image {
      width: 91px;
      height: 54px;
    }
  }
`;

export const ProjectListWrapper = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  grid-gap: 20px;
  margin-top: 50px;
`;

export const Header = styled.div`
  margin-top: 50px;
  display: flex;
  justify-content: space-between;

  input {
    width: 315px;
    height: 48px;
    border: none;
    padding: 0 40px;
    font-size: 16px;
    border: 1px solid transparent;
    transition: all 0.23s;

    border-radius: 10px;

    &::placeholder {
      font-size: 14px;
      line-height: 16px;
    }

    &:hover,
    &:focus-visible {
      outline: none;
      border: 1px solid gray;
    }

    &::placeholder {
      color: #a7a7a7;
    }
  }

  div {
    position: relative;

    .search {
      position: absolute;
      top: 25%;
      width: 27px !important;
      height: 31px !important;
      left: 8px;
      transform: translate(0, -20%);
    }
  }
`;
