import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { ModalWindow } from "./ModalWindow";
import { Project } from "../../types/Project";
import { DrawingPage } from "./DrawingPage";
import ProjectService from "../../services/ProjectService";

const Container = styled.div`
	width: 100%;
	height: 100vh;
	background: #484848;
	display: flex;
	justify-content: center;
	align-items: center;
`;

const AwaitingRequest = styled.text`
	color: #ffffff;
	font-family: Silka, serif;
	font-size: 1.5rem;
	font-weight: 500;
`;

export const RequestHandler = () => {
	const [project, setProject] = useState<Project>();
	const [active, setActive] = useState<boolean>(false);
	const [activeInterval, setActiveInterval] =
		useState<ReturnType<typeof setTimeout>>();

	useEffect(() => {
		const interval = setInterval(() => {
			ProjectService.checkForProjectRequest().then(res => {
				const data = res.data as { requestId: string; project: Project | null };
				if (data.project) {
					setProject(data.project);
					setActive(true);
				}
			});
		}, 1000);

		setActiveInterval(interval);

		return () => clearInterval(interval);
	}, []);

	useEffect(() => {
		if (project && activeInterval) {
			clearInterval(activeInterval);
			ProjectService.deleteRequests().then(() => console.log("requests removed"));
		}
	}, [project, activeInterval]);

	localStorage.setItem("AccessToken", project?.accessToken?.value || "");

	if (
		project?.aerialViewImage === null ||
		project?.aerialViewImage === undefined
	) {
		return (
			<Container>
				<AwaitingRequest>Awaiting for Request...</AwaitingRequest>
				<ModalWindow project={project} isActive={active} />
			</Container>
		);
	} else {
		return <DrawingPage projectId={project?.primaryId} />;
	}
};
