import {
	APP_IMAGE_EXTENSIONS,
	BYTES_IN_MEGABYTE,
	MAX_IMAGE_HEIGHT,
	MAX_IMAGE_SIZE,
	MAX_IMAGE_WIDTH,
} from "./constants/constants";
import { v4 as uuidv4 } from "uuid";
import ImageUploader from "react-images-upload";
import React, { useState } from "react";
import CircularProgress from "@material-ui/core/CircularProgress";
import { loadImageDownloadUrl, saveImage } from "../../persistance/persistence";

export const AriaViewUploaderComponent = (props: {
	setImageLoadUrl: (loadUrl: string) => void;
}) => {
	const { setImageLoadUrl } = props;

	const [imageValidationErrorMessage, setImageValidationErrorMessage] =
		useState("");
	const [isLoading, setIsLoading] = useState(false);

	const handleImageUploaded = async (image: any) => {
		setIsLoading(true);
		const url = uuidv4();
		await saveImage(url, image);
		const ariaPreViewImage = await loadImageDownloadUrl(url);
		setImageLoadUrl(ariaPreViewImage!);
	};

	const validateImage = (image: File) => {
		handleImageUploaded(image).then();
	};

	return (
		<div>
			{isLoading ? (
				<div>
					Processing image...
					<br />
					<CircularProgress />
				</div>
			) : (
				<ImageUploader
					withIcon={false}
					withPreview={false}
					singleImage={true}
					label={
						`Max file size: ${
							MAX_IMAGE_SIZE / BYTES_IN_MEGABYTE
						} mb; minimal resolution: ${MAX_IMAGE_WIDTH} * ${MAX_IMAGE_HEIGHT} px;
              extension: ${APP_IMAGE_EXTENSIONS} \n\n` +
						imageValidationErrorMessage
					}
					labelStyles={
						!imageValidationErrorMessage
							? { whiteSpace: "pre-line" }
							: { color: "red", fontSize: "15px" }
					}
					buttonText='Upload an aerial view'
					onChange={images => validateImage(images[0])}
					fileSizeError=''
					fileTypeError=''
					errorStyle={{ fontSize: "0px" }}
				/>
			)}
		</div>
	);
};
