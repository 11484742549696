import React, { useState } from "react";
import { useAsyncDebounce } from "react-table";
import { Wrapper } from "./styles";
import { BiSearchAlt2 } from "react-icons/bi";

const GlobalSearch = (props: { filter: any; setFilter: any }) => {
	const [value, setValue] = useState(props.filter);

	const onChange = useAsyncDebounce(value => {
		props.setFilter(value || undefined);
	}, 200);

	return (
		// <GlobalFilterSpan>
		<Wrapper>
			<span className='searchBar'>
				{/* //set empty init value */}
				<BiSearchAlt2 className='search' />
				<input
					value={value || ""}
					onChange={e => {
						setValue(e.target.value);
						onChange(e.target.value);
					}}
					placeholder='Search Unit'
				/>
			</span>
		</Wrapper>
		// </GlobalFilterSpan>
	);
};

export default GlobalSearch;
