export type EditorSettings = {
	cornersRadius: number;
	strokeColor: string;
	strokeOpacity: number;
	strokeWidth: number;
	fillColor: string;
	fillOpacity: number;
	availableColor: string;
	availableOpacity: number;
	offerColor: string;
	offerOpacity: number;
	soldColor: string;
	soldOpacity: number;
};

export const DEFAULT_EDITOR_SETTINGS = {
	cornersRadius: 5,
	strokeWidth: 1,
	strokeColor: "#FFFFFF",
	strokeOpacity: 0.7,
	fillColor: "#508ef2",
	fillOpacity: 0.4,
	availableColor: "#458107",
	availableOpacity: 0.2,
	offerColor: "#fbf01f",
	offerOpacity: 0.2,
	soldColor: "#ea462f",
	soldOpacity: 0.2,
};
