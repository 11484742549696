import styled from "styled-components";

export const ResetPassword = styled.div`
	width: 100%;
	height: 100vh;
	display: flex;
	justify-content: center;
	align-items: center;
	flex-direction: column;
`;

export const StyledInput = styled.input`
	border: 1px solid #cecece;
	padding: 16px 16px;
	width: 315px;
	height: 40px;
	font-size: 16px;
	border-radius: 0;
	font-family: "Galano Grotesque";
`;

export const WrapperLabelInput = styled.div`
	display: flex;
	align-items: center;
	position: relative;
	margin-top: 20px;
`;

export const StyledLable = styled.label`
	font-size: 16px;
	margin-right: 10px;
	font-family: Silka, sans-serif;
	position: absolute;
`;

export const ChangePasswordButton = styled.button`
	margin-top: 30px;
	background: #2e7dcb;
	cursor: pointer !important;
	padding: 16px 0;
	border: none;
	border-radius: 4px;
	color: #ffffff;
	width: 214px;
	justify-self: end;

	&:hover {
		color: black;
	}
`;
