export const BACK_URL = process.env.REACT_APP_API_URL;

export const ACCOUNT_URL = BACK_URL + "accounts/";
export const AUTH_URL = BACK_URL + "auth/";
export const PROJECT_URL = BACK_URL + "projects/";
export const PRIMARY_PROJECT_URL = BACK_URL + "projects/primary/";
export const USER_URL = BACK_URL + "users/";
export const TYPE_URL = BACK_URL + "types/";
export const HOUSE_URL = BACK_URL + "houses/";
export const SVG_URL = BACK_URL + "svgs/";
