import React from 'react';
import { useSelector } from 'react-redux';
import { getUser } from 'platform/redux/selectors/user';
import { getIsFlowStarted } from 'platform/redux/selectors/newUserFlow';
import NavBar from '../NavBar';
import LoggedUserHeader from './LoggedUserHeader';

const Header = () => {
  const user = useSelector(getUser);
  const isFlowStarted = useSelector(getIsFlowStarted);

  return <>{!isFlowStarted && (user ? <LoggedUserHeader /> : <NavBar />)}</>;
};

export default Header;
