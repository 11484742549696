import React from "react";
import {
	Button,
	Dialog,
	DialogContent,
	DialogTitle,
	Divider,
	List,
	ListItem,
	Slider,
} from "@material-ui/core";
import CustomColorPicker from "./CustomColorPicker";
import { DEFAULT_EDITOR_SETTINGS } from "../../../../../types/EditorSettings";

const PolygonStyler = (props: {
	stylerOpened: boolean;
	polygonColor: string;
	polygonOpacity: number;
	availablePolygonColor: string;
	availableOpacity: number;
	offerPolygonColor: string;
	offerOpacity: number;
	soldPolygonColor: string;
	soldOpacity: number;
	strokeColor: string;
	strokeOpacity: number;
	strokeWidth: number;
	handleClose: () => void;
	handleEditorSettingChange: (setting: string, value: string | number) => void;
}) => {
	const {
		stylerOpened,
		polygonColor,
		availablePolygonColor,
		availableOpacity,
		offerPolygonColor,
		offerOpacity,
		soldPolygonColor,
		soldOpacity,
		polygonOpacity,
		strokeColor,
		strokeOpacity,
		strokeWidth,
		handleClose,
		handleEditorSettingChange,
	} = props;

	const listItemStyle = {
		display: "flex",
		justifyContent: "space-between",
	};

	return (
		<Dialog
			open={stylerOpened}
			onClose={handleClose}
			fullWidth
			maxWidth='xs'
			style={{
				textAlign: "center",
			}}
		>
			<DialogTitle>Project editor settings</DialogTitle>
			<DialogContent>
				<List dense>
					<ListItem style={listItemStyle}>
						<h3>Default polygon color</h3>
						<CustomColorPicker
							color={polygonColor}
							opacity={polygonOpacity}
							handleColorChange={color =>
								handleEditorSettingChange("fillColor", color)
							}
							handleOpacityChange={opacity =>
								handleEditorSettingChange("fillOpacity", opacity)
							}
						/>
					</ListItem>
					<ListItem
						style={{
							display: "block",
						}}
					>
						<div
							style={{
								minWidth: "100%",
								display: "flex",
								justifyContent: "space-between",
								alignItems: "center",
							}}
						>
							<h3>Stroke</h3>
							<CustomColorPicker
								color={strokeColor}
								opacity={strokeOpacity}
								handleColorChange={color =>
									handleEditorSettingChange("strokeColor", color)
								}
								handleOpacityChange={opacity =>
									handleEditorSettingChange("strokeOpacity", opacity)
								}
							/>
						</div>
						<div>
							<Slider
								min={0}
								step={1}
								max={10}
								value={strokeWidth}
								aria-label='Small'
								valueLabelDisplay='auto'
								onChange={(_, value) =>
									handleEditorSettingChange("strokeWidth", Number(value))
								}
							/>
						</div>
					</ListItem>
					<ListItem style={listItemStyle}>
						<h3>General opacity</h3>
						<Slider
							min={0.1}
							step={0.1}
							max={1}
							value={polygonOpacity}
							aria-label='Small'
							valueLabelDisplay='auto'
							onChange={(_, value) => {
								handleEditorSettingChange("fillOpacity", Number(value));
								handleEditorSettingChange("availableOpacity", Number(value));
								handleEditorSettingChange("offerOpacity", Number(value));
								handleEditorSettingChange("soldOpacity", Number(value));
								handleEditorSettingChange("strokeOpacity", Number(value));
							}}
						/>
					</ListItem>
					<Divider />
					<ListItem style={listItemStyle}>
						<h3>Available</h3>
						<div
							style={{
								display: "flex",
								alignItems: "center",
							}}
						>
							<Button
								variant='contained'
								style={{
									maxWidth: "75px",
									maxHeight: "25px",
									backgroundColor: DEFAULT_EDITOR_SETTINGS.availableColor,
								}}
								onClick={() =>
									handleEditorSettingChange(
										"availableColor",
										DEFAULT_EDITOR_SETTINGS.availableColor
									)
								}
							>
								Default
							</Button>
							<CustomColorPicker
								color={availablePolygonColor}
								opacity={availableOpacity}
								handleColorChange={color =>
									handleEditorSettingChange("availableColor", color)
								}
								handleOpacityChange={opacity =>
									handleEditorSettingChange("availableOpacity", opacity)
								}
							/>
						</div>
					</ListItem>
					<ListItem style={listItemStyle}>
						<h3>Offer</h3>
						<div
							style={{
								display: "flex",
								alignItems: "center",
							}}
						>
							<Button
								variant='contained'
								style={{
									maxWidth: "75px",
									maxHeight: "25px",
									backgroundColor: DEFAULT_EDITOR_SETTINGS.offerColor,
								}}
								onClick={() =>
									handleEditorSettingChange(
										"offerColor",
										DEFAULT_EDITOR_SETTINGS.offerColor
									)
								}
							>
								Default
							</Button>
							<CustomColorPicker
								color={offerPolygonColor}
								opacity={offerOpacity}
								handleColorChange={color =>
									handleEditorSettingChange("offerColor", color)
								}
								handleOpacityChange={opacity =>
									handleEditorSettingChange("offerOpacity", opacity)
								}
							/>
						</div>
					</ListItem>
					<ListItem style={listItemStyle}>
						<h3>Sold</h3>
						<div
							style={{
								display: "flex",
								alignItems: "center",
							}}
						>
							<Button
								variant='contained'
								style={{
									maxWidth: "75px",
									maxHeight: "25px",
									backgroundColor: DEFAULT_EDITOR_SETTINGS.soldColor,
								}}
								onClick={() =>
									handleEditorSettingChange(
										"soldColor",
										DEFAULT_EDITOR_SETTINGS.soldColor
									)
								}
							>
								Default
							</Button>
							<CustomColorPicker
								color={soldPolygonColor}
								opacity={soldOpacity}
								handleColorChange={color =>
									handleEditorSettingChange("soldColor", color)
								}
								handleOpacityChange={opacity =>
									handleEditorSettingChange("soldOpacity", opacity)
								}
							/>
						</div>
					</ListItem>
				</List>
			</DialogContent>
		</Dialog>
	);
};

export default PolygonStyler;
