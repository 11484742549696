import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import Tool from "tool/components/Tool";
import { Project } from "types/Project";
import ProjectService from "services/ProjectService";
import { AppMode } from "tool/components/context/AppModeContext";

const View = () => {
	const { id } = useParams<{ id: string }>();

	const [project, setProject] = useState<Project>();

	useEffect(() => {
		const href = window.location.href;

		if (href.includes("draw")) {
			const token = localStorage.getItem("AccessToken");
			if (token && id) {
				ProjectService.getProjectByPrimaryIdPlain(id, token).then(project =>
					setProject(project)
				);
			}
		} else {
			ProjectService.getProjectPlain(id).then(project => setProject(project));
		}
	}, []);

	return <>{project && <Tool project={project} appMode={AppMode.view} />}</>;
};

export default View;
