import React from 'react';
import { useHistory } from 'react-router-dom';
import { PROJECTS_PATH } from '../../constants/PathConstants';
import ProjectList from './ProjectList';
import NewProjectStep from '../new-user-flow/steps/NewProjectStep';
import { ProjectData } from '../new-user-flow/NewUserFlow';

const Projects = (props: { projects: any; setProjects: (projects: any) => void }) => {
  const history = useHistory();
  const [projectData, setProjectData] = React.useState<ProjectData>({
    project: {
      id: '-1',
      name: '',
      houses: [],
      aerialViewImage: '',
    },
    numberOfHouses: 0,
  });

  return (
    <div>
      {/* <CreateProject/> */}
      <NewProjectStep projectData={projectData} setProjectData={setProjectData} headerless />

      {props.projects && props.projects.length > 0 && (
        <ProjectList
          data={props.projects}
          setData={props.setProjects}
          onGo={(id: string) => {
            history.push(PROJECTS_PATH + id);
          }}
        />
      )}
    </div>
  );
};

export default Projects;
