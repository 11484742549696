import styled from 'styled-components';

export const ButtonIcon = styled.div`
  display: flex;
  width: 100%;
  justify-content: center;
  padding: 5px 0;
  position: relative;
  cursor: pointer;

  &:hover {
  }

  img {
    height: 40px;
  }
`;

export const DragToolBoxIcon = styled.div`
  display: flex;
  width: 100%;
  justify-content: center;
  margin: 9px 0 14px 0;
  cursor: move;

  img {
    width: 35px;
    height: 6px;
  }
`;

export const ButtonIconPolygon = styled.div`
  display: flex;
  width: 100%;
  padding: 5px 0;
  cursor: pointer;

  img {
    margin-left: 21px;
    height: 38px;
  }
`;

export const ArrowsWrapper = styled.div`
  display: flex;
  width: 100%;
  padding: 10px 7px;
  justify-content: space-between;
  align-items: center;
`;

export const ArrowDivider = styled.div`
  width: 1px;
  align-self: stretch;
  background-color: #707070;
`;

export const ArrowButton = styled.div`
  display: flex;
  cursor: pointer;
  padding: 4px;
`;

export const ArrowImage = styled.img`
  max-width: 19px;
`;
