import React, { useState } from "react";
import * as BiIcons from "react-icons/bi";
import { PublishIframe } from "platform/pages/publish/PublishElements";
import { IFramePopUp } from "../IFramePopUp";

export const IFrameClipboardButton = (props: { projectId?: string }) => {
	const [isDialogOpen, setIsDialogOpen] = useState<boolean>(false);

	const iframeContent = `
    <iframe id="iframe-wmdule" 
        src="${window.location.origin}/view/${props.projectId}" 
        height="700px" 
        width="100%" 
        frameborder="0" 
        scrolling="no">
    </iframe>
  `;

	return (
		<>
			<PublishIframe onClick={() => setIsDialogOpen(true)}>
				<BiIcons.BiCodeAlt style={{ marginRight: "4px" }} />
				Copy iFrame
			</PublishIframe>
			<IFramePopUp
				isPopupOpen={isDialogOpen}
				iFrameContent={iframeContent}
				handleClose={() => {
					setIsDialogOpen(false);
				}}
			/>
		</>
	);
};
