import styled from "styled-components";

export const SVGCircle = styled.circle<{
	$currentScale: number;
	$sizer: number;
}>`
	fill: white;
	r: ${({ $currentScale, $sizer }) => $sizer / $currentScale}pt;
	stroke: ${props => props.fill};
	stroke-width: ${({ $currentScale, $sizer }) => $sizer / $currentScale}pt;
	stroke-dasharray: 0;
	stroke-linejoin: round;
`;

export const SVGMagneticCircle = styled.circle<{ $currentScale: number }>`
	fill: white;
	fill-opacity: 0;
	stroke: red;
	stroke-width: ${({ $currentScale }) => 2 / $currentScale}pt;
	stroke-opacity: 0;
	r: ${({ $currentScale }) => 2 / $currentScale}pt;

	&:hover {
		fill-opacity: 1;
		stroke-opacity: 1;

		r: ${({ $currentScale }) => 2 / $currentScale}pt;
	}
`;

export const SVGPolygon = styled.polygon<{
	$currentScale: number;
	$strokeWidth: string;
	$isActive: boolean;
	$fillOpacity: number;
	$strokeOpacity: number;
}>`
	stroke-width: ${({ $currentScale, $strokeWidth }) =>
		Number($strokeWidth) / $currentScale};
	fill-opacity: ${({ $fillOpacity }) => $fillOpacity};
	stroke-opacity: ${({ $strokeOpacity }) => $strokeOpacity};

	&:hover {
		stroke-width: ${({ $currentScale, $strokeWidth }) =>
			Number($strokeWidth + 1) / $currentScale};
		fill-opacity: ${({ $isActive, $fillOpacity }) =>
			$fillOpacity
				? $isActive
					? Number($fillOpacity) + 0.2
					: $fillOpacity
				: 0};
		stroke-opacity: ${({ $strokeOpacity }) => $strokeOpacity};
	}
`;

export const MagneticSVGPolygon = styled.polygon<{ $strokeWidth?: string }>`
	fill-opacity: 0;
	stroke-width: ${({ $strokeWidth }) => Number($strokeWidth)};

	&:hover {
		fill-opacity: 0;
	}
`;

export const IdCircle = styled(SVGCircle)<{
	$disablePointerEvents: boolean;
	$isMove: boolean;
}>`
	fill: white;
	stroke: white;
	pointer-events: ${({ $disablePointerEvents }) =>
		$disablePointerEvents ? "none" : "auto"};
	cursor: ${({ $isMove }) => ($isMove ? "move" : "auto")};
`;

export const IdText = styled.text<{
	$reduceFontSize: boolean;
	$currentScale: number;
	$isMove: boolean;
}>`
	cursor: ${({ $isMove }) => ($isMove ? "move" : "auto")};
	font-size: ${({ $reduceFontSize, $currentScale }) =>
		$reduceFontSize ? `${6 / $currentScale}px` : `${7 / $currentScale}px`};
	font-family: "Silka", sans-serif;
	font-weight: bold;
	color: black;
	position: absolute;
`;
