import { AnyAction } from 'redux';
import { FETCH_STATE, VALIDATE_INPUTS } from '../actions/Actions';
import { ValidationState } from 'types/state';

const initState: ValidationState = {
  errorMessages: null,
};

export const validationReducer = (state = initState, action: AnyAction) => {
  switch (action.type) {
    case VALIDATE_INPUTS:
      return {
        ...state,
        errorMessages: [...action.payload],
      };
    case FETCH_STATE:
      return {
        ...state,
      };
    default:
      return { ...state };
  }
};
