export const validateNotEmpty = (input: any) => {
	if (!input.length) {
		return "Must not be empty";
	}

	return "";
};

export const validateLength = (input: any, from?: number, to?: number) => {
	if (from) {
		if (input.length < from) {
			return "Should be longer than " + from + " characters";
		}
	}
	if (to) {
		if (input.length > to) {
			return "Should be shorter than " + to + " characters";
		}
	}

	return "";
};

export const validateEmail = (input: string) => {
	const emailRegex = /^\w+@[a-zA-Z_]+?\.[a-zA-Z]{2,3}$/;
	if (input.toLowerCase().search(emailRegex) === -1) {
		return "Invalid email!";
	}

	return "";
};

export const areAllFieldsValid = (...errorMessages: string[]) =>
	!errorMessages.some(errorMessage => errorMessage.length !== 0);
