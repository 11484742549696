import React from "react";
import Header from "../headers/Header";

const HeaderLayout = ({ children }: any) => {
	return (
		<>
			<Header />
			{children}
		</>
	);
};

export default HeaderLayout;
