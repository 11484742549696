import { createStyles, Theme, Tooltip } from '@material-ui/core';
import React, { useContext, useEffect, useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { AppMode, AppModeContext } from '../../context/AppModeContext';
import { HouseContext } from '../../context/HouseContext';
import { ToolboxContext } from '../../context/ToolboxContext';
import PolygonStyler from './editor-settings/PolygonStyler';
import { House } from '../../../../types/House';
import { DEFAULT_EDITOR_SETTINGS, EditorSettings } from '../../../../types/EditorSettings';
import {
  ArrowsWrapper,
  ArrowButton,
  ButtonIcon,
  ButtonIconPolygon,
  DragToolBoxIcon,
  ArrowImage,
  ArrowDivider,
} from './styles';
import pointerIcon from '../../../../assets/images/icons/tool/mouse.svg';
import pointerIconActive from '../../../../assets/images/icons/tool/mouse-active.svg';
import addPolygonIconActive from '../../../../assets/images/icons/tool/polygon-add-active.svg';
import addPolygonIcon from '../../../../assets/images/icons/tool/polygon-add.svg';
import editPolygonIcon from '../../../../assets/images/icons/tool/polygon-edit.svg';
import editPolygonIconActive from '../../../../assets/images/icons/tool/polygon-edit-active.svg';
import deletePolygonIcon from '../../../../assets/images/icons/tool/polygon-delete.svg';
import deletePolygonIconActive from '../../../../assets/images/icons/tool/polygon-delete-active.svg';
import addGuideLinesIcon from '../../../../assets/images/icons/tool/guide-add.svg';
import addGuideLinesIconActive from '../../../../assets/images/icons/tool/guide-add-active.svg';
import toggleGuideLinesIconOn from '../../../../assets/images/icons/tool/guide-on.svg';
import toggleGuideLinesIconOff from '../../../../assets/images/icons/tool/guide-off.svg';
import guideLinesToFrontIconOn from '../../../../assets/images/icons/tool/guide-to-front-on.svg';
import guideLinesToFrontIconOff from '../../../../assets/images/icons/tool/guide-to-front-off.svg';
import toggleImgIconOn from '../../../../assets/images/icons/tool/image-on.svg';
import toggleImgIconOff from '../../../../assets/images/icons/tool/image-off.svg';
import resizeImgIcon from '../../../../assets/images/icons/tool/fitscreen.svg';
import replaceImgIcon from '../../../../assets/images/icons/tool/image-replace.svg';
import dragToolBoxIcon from '../../../../assets/images/icons/tool/drag-toolBox.svg';
import undoIcon from '../../../../assets/images/icons/tool/arrowLeft.svg';
import redoIcon from '../../../../assets/images/icons/tool/arrowRight.svg';

import { useDrag } from 'react-dnd';
import { toast } from 'react-toastify';
import styled from 'styled-components';
import { v4 as uuidv4 } from 'uuid';
import { loadImageDownloadUrl, saveImage } from '../../../../persistance/persistence';

export type Settings =
  | 'strokeWidth'
  | 'cornersRadius'
  | 'strokeColor'
  | 'fillColor'
  | 'fillOpacity'
  | 'availableColor'
  | 'offerColor'
  | 'soldColor'
  | 'availableOpacity'
  | 'offerOpacity'
  | 'soldOpacity';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    toolBoxGrid: {},
    boxGrid: {
      width: '75px',
      display: 'flex',
      flexDirection: 'column',
      position: 'fixed',
      paddingLeft: '0px',
      zIndex: 99,
      backgroundColor: 'white',
      alignItems: 'center',
      marginBottom: '2px',
      borderRadius: '2px',
      padding: '8px 0 8px 0',
    },
    buttonPaddedIcons: {
      height: '35px',
    },
    disabledButton: {
      backgroundColor: 'transparent',
      opacity: '0.6',
    },
    button: {
      display: 'flex',
      justifyContent: 'center',
      paddingTop: '13px',
    },
    activeButton: {
      backgroundColor: '#C1C1C1',
    },
    textField: {
      width: '75px',
    },
    addButton: {
      width: '100%',
      marginBottom: '10px',
    },
    toggle: {
      border: '0',
      borderRadius: '35px',
      marginBottom: '2px',
    },
    collapseButton: {
      width: '20px',
      height: '40px',
      borderRadius: 0,
      backgroundColor: '#C1C1C1',
    },
    arrowLeftBox: {
      position: 'absolute',
      left: '72px',
      top: '47px',
      borderRadius: 0,
    },
    arrowRightBox: {
      marginTop: '10px',
      height: '40px',
      borderRadius: 0,
    },
    drawSelected: {
      backgroundColor: '#C1C1C1',
    },
  }),
);

export const ToolBoxComponent = (props: {
  houses: House[];
  editorSettings: EditorSettings;
  updateEditorSettings: (es: EditorSettings) => void;
  isUndoHistory: boolean;
  isRedoHistory: boolean;
  drawInnerLine: () => void;
  handleAddHouse: () => void;
  handleReturnToProjectsList: () => void;
  updateProjectData: (es: EditorSettings) => void;
  tableButton?: boolean;
  deletePolygon: any;
  editPolygon: any;
  drawPolygon: any;
  handleUndo: any;
  handleRedo: any;
  left: any;
  top: any;
  id: any;
  polygonId: any;
  setSelectedPlotId: any;
  setImageLoadUrl: any;
}) => {
  const classes = useStyles();
  const {
    editorSettings,
    updateEditorSettings,
    updateProjectData,
    deletePolygon,
    drawPolygon,
    handleUndo,
    handleRedo,
    left,
    top,
    id,
    polygonId,
    setSelectedPlotId,
    setImageLoadUrl,
  } = props;
  const { appMode } = useContext(AppModeContext);
  const {
    isDrawingButtonClicked,
    isEditButtonClicked,
    setIsDrawingButtonClicked,
    setIsEditButtonClicked,
    setIsDrawGuideLineButtonClicked,
    isDrawGuideLineButtonClicked,
    setIsSavePolygonButton,
    imageShown,
    setImageShown,
    guideLinesShown,
    setGuideLinesShown,
    guideLinesOnTop,
    setGuideLinesOnTop,
    setIsResetTransform,
    setIsPointerButtonClicked,
    isPointerButtonClicked,
  } = useContext(ToolboxContext);
  const { house, setPolygonId } = useContext(HouseContext);
  const [polygonStylerOpened, setPolygonStylerOpened] = useState<boolean>(false);
  const hiddenImageInput = React.useRef(null);

  useEffect(() => {
    if (house && house.polygons.length > 0) {
      setIsSavePolygonButton(false);
    } else {
      setIsSavePolygonButton(true);
    }
  }, [house]);

  if (appMode === AppMode.view) {
    return null;
  }

  const changeEditorSetting = (setting: string, value: any): void => {
    switch (setting) {
      case 'strokeWidth':
        editorSettings.strokeWidth = Number(value);
        break;

      case 'strokeColor':
        editorSettings.strokeColor = value;
        break;

      case 'strokeOpacity':
        editorSettings.strokeOpacity = Number(value);
        break;

      case 'fillColor':
        editorSettings.fillColor = value;
        break;

      case 'fillOpacity':
        editorSettings.fillOpacity = Number(value);
        break;

      case 'availableColor':
        editorSettings.availableColor = value;
        break;

      case 'availableOpacity':
        editorSettings.availableOpacity = Number(value);
        break;

      case 'offerColor':
        editorSettings.offerColor = value;
        break;

      case 'offerOpacity':
        editorSettings.offerOpacity = Number(value);
        break;

      case 'soldColor':
        editorSettings.soldColor = value;
        break;

      case 'soldOpacity':
        editorSettings.soldOpacity = Number(value);
        break;
    }

    updateEditorSettings(editorSettings);
  };

  const [{ isDragging }, drag] = useDrag(
    () => ({
      type: 'box',
      item: { id, left, top },
      collect: (monitor) => ({
        isDragging: monitor.isDragging(),
      }),
    }),
    [id, left, top],
  );

  const handleReplaceImage = async (e: any) => {
    const file = e.target.files[0];
    if (file) {
      toast.info('Image is uploading...');
      const url = uuidv4();
      await saveImage(url, file);
      const ariaPreViewImage = await loadImageDownloadUrl(url);
      setImageLoadUrl(ariaPreViewImage || '');
      toast.success('Image uploaded');
    }
  };

  // @ts-ignore
  return (
    <div ref={drag} className={classes.boxGrid} style={{ left, top }}>
      <Tooltip title={`drag toolbox`} placement="left" arrow>
        <DragToolBoxIcon>
          <img src={dragToolBoxIcon} />
        </DragToolBoxIcon>
      </Tooltip>

      <Tooltip title={`pointer`} placement="left" arrow>
        <ButtonIconPolygon
          onClick={() => {
            setIsPointerButtonClicked(true);
            setIsDrawingButtonClicked(false);
            setIsEditButtonClicked(false);
            setIsDrawGuideLineButtonClicked(false);
            setPolygonId(null);
            setSelectedPlotId(null);
          }}
        >
          <img src={isPointerButtonClicked ? pointerIconActive : pointerIcon} />
        </ButtonIconPolygon>
      </Tooltip>
      <Tooltip title={`add polygon (p)`} placement="left" arrow>
        <ButtonIconPolygon
          onClick={() => {
            drawPolygon();
            setSelectedPlotId(null);
            setIsEditButtonClicked(false);
          }}
        >
          <img src={isDrawingButtonClicked ? addPolygonIconActive : addPolygonIcon} />
        </ButtonIconPolygon>
      </Tooltip>
      <Tooltip title={`edit polygon (e)`} placement="left" arrow>
        <ButtonIconPolygon
        // onClick={() => {
        // 	polygonId && editPolygon()
        // 	setIsDrawingButtonClicked(false);
        // 	polygonId ? toast.info('Edit mode ON') : toast.warn('Select polygon to edit!')

        // }}
        >
          <img src={isEditButtonClicked ? editPolygonIconActive : editPolygonIcon} />
        </ButtonIconPolygon>
      </Tooltip>
      <Tooltip title={`delete polygon (delete)`} placement="left" arrow>
        <ButtonIconPolygon
          onClick={() => {
            polygonId && deletePolygon();
            polygonId ? toast.success('Polygon deleted') : toast.warn('Select polygon to delete!');
            setIsDrawingButtonClicked(false);
            setIsEditButtonClicked(false);
          }}
        >
          <img
            src={deletePolygonIcon}
            onMouseLeave={(e: any) => e.target.setAttribute('src', deletePolygonIcon)}
            onMouseEnter={(e: any) => e.target.setAttribute('src', deletePolygonIconActive)}
          />
        </ButtonIconPolygon>
      </Tooltip>

      <LineSeparator marginBottom={20} />

      <Tooltip title={`add guidelines (g)`} placement="left" arrow>
        <ButtonIcon
          onClick={() => {
            setIsDrawGuideLineButtonClicked(!isDrawGuideLineButtonClicked);
            setIsDrawingButtonClicked(false);
            setIsEditButtonClicked(false);
            setSelectedPlotId(null);
            setPolygonId(null);
          }}
        >
          <img src={isDrawGuideLineButtonClicked ? addGuideLinesIconActive : addGuideLinesIcon} />
        </ButtonIcon>
      </Tooltip>
      <Tooltip title={`toggle guidelines (t)`} placement="left" arrow>
        <ButtonIcon
          onClick={() => {
            if (guideLinesShown) {
              setGuideLinesOnTop(false);
            }
            setGuideLinesShown(!guideLinesShown);
          }}
        >
          <img src={guideLinesShown ? toggleGuideLinesIconOn : toggleGuideLinesIconOff} />
        </ButtonIcon>
      </Tooltip>
      <Tooltip title={`guidelines to front (f)`} placement="left" arrow>
        <ButtonIcon
          onClick={() => {
            if (guideLinesShown) {
              setGuideLinesOnTop(!guideLinesOnTop);
            }
          }}
        >
          <img src={guideLinesOnTop ? guideLinesToFrontIconOff : guideLinesToFrontIconOn} />
        </ButtonIcon>
      </Tooltip>
      <Tooltip title={`toggle image (i)`} placement="left" arrow>
        <ButtonIcon
          onClick={() => {
            setImageShown(!imageShown);
          }}
        >
          <img src={imageShown ? toggleImgIconOn : toggleImgIconOff} />
        </ButtonIcon>
      </Tooltip>
      <Tooltip title={`fit image (ctrl + 0)`} placement="left" arrow>
        <ButtonIcon
          onClick={() => {
            setIsResetTransform(true);
            setIsDrawingButtonClicked(false);
          }}
        >
          <img src={resizeImgIcon} />
        </ButtonIcon>
      </Tooltip>
      <Tooltip title={`replace image`} placement="left" arrow>
        <ButtonIcon
          // @ts-ignore
          onClick={() => hiddenImageInput.current.click()}
        >
          <input
            ref={hiddenImageInput}
            style={{ display: 'none' }}
            type="file"
            onChange={handleReplaceImage}
          />
          <img src={replaceImgIcon} />
        </ButtonIcon>
      </Tooltip>

      <LineSeparator marginBottom={0} />
      <ArrowsWrapper>
        <Tooltip title={`undo (ctrl + z)`} placement="left" arrow>
          <ArrowButton
            onClick={() => {
              handleUndo();
              setIsPointerButtonClicked(true);
              setIsDrawingButtonClicked(false);
              setIsEditButtonClicked(false);
              setIsDrawGuideLineButtonClicked(false);
              setPolygonId(null);
              setSelectedPlotId(null);
            }}
          >
            <ArrowImage src={undoIcon} />
          </ArrowButton>
        </Tooltip>
        <ArrowDivider />
        <Tooltip title={`redo (ctrl + y)`} placement="left" arrow>
          <ArrowButton
            onClick={() => {
              handleRedo();
              setIsPointerButtonClicked(true);
              setIsDrawingButtonClicked(false);
              setIsEditButtonClicked(false);
              setIsDrawGuideLineButtonClicked(false);
              setPolygonId(null);
              setSelectedPlotId(null);
            }}
          >
            <ArrowImage src={redoIcon} />
          </ArrowButton>
        </Tooltip>
      </ArrowsWrapper>
      {/* <Tooltip title={`edit colors`} placement='right' arrow>
					<IconButton
						size={"small"}
						color={imageShown ? "primary" : undefined}
						onClick={() => setPolygonStylerOpened(true)}
						className={classes.button}
					>
						<PaletteIcon fontSize={"small"} className={classes.buttonPaddedIcons} />
					</IconButton>
				</Tooltip> */}
      <PolygonStyler
        stylerOpened={polygonStylerOpened}
        polygonColor={editorSettings.fillColor}
        polygonOpacity={editorSettings.fillOpacity}
        availablePolygonColor={editorSettings.availableColor || DEFAULT_EDITOR_SETTINGS.availableColor}
        availableOpacity={editorSettings.availableOpacity}
        offerPolygonColor={editorSettings.offerColor || DEFAULT_EDITOR_SETTINGS.offerColor}
        offerOpacity={editorSettings.offerOpacity}
        soldPolygonColor={editorSettings.soldColor || DEFAULT_EDITOR_SETTINGS.soldColor}
        soldOpacity={editorSettings.soldOpacity}
        strokeColor={editorSettings.strokeColor}
        strokeOpacity={editorSettings.strokeOpacity}
        strokeWidth={editorSettings.strokeWidth}
        handleClose={() => {
          updateProjectData(editorSettings);
          setPolygonStylerOpened(false);
        }}
        handleEditorSettingChange={changeEditorSetting}
      />
    </div>
  );
};

const LineSeparator = styled.hr<{ marginBottom: number }>`
  width: 42px;
  height: 1px;
  background-color: #707070;
  margin-top: 20px;
  margin-bottom: ${(props) => props.marginBottom}px;
`;
