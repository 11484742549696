import { useCallback, useContext, useState } from 'react';
import cloneDeep from 'clone-deep';
import ProjectService from 'services/ProjectService';
import { mapHousePropertiesToHouses } from 'platform/utils/HouseUtils';
import { AdditionalProperty, Project as ProjectType } from 'types/Project';
import { projectTableColumns } from 'platform/components/Table/house-table/HouseTable';
import { toast } from 'react-toastify';
import { useDispatch, useSelector } from 'react-redux';
import { getView } from 'platform/redux/selectors/tool';
import { getProject } from 'platform/redux/selectors/project';
import { ToolboxContext } from 'tool/components/context/ToolboxContext';
import { CustomColumnType } from 'platform/components/Table/type-table/TypeTable';
import { TOOL_VIEW_SET } from 'platform/redux/actions/Actions';
import { useParams } from 'react-router-dom';

export const useProjectActions = () => {
  const dispatch = useDispatch();
  const { id } = useParams<{ id: string }>();
  const { isDrawingButtonClicked } = useContext(ToolboxContext);

  const view = useSelector(getView);
  const project = useSelector(getProject);

  const [stateProject, setStateProject] = useState<ProjectType>();
  const [additionalColumns, setAdditionalColumns] = useState<CustomColumnType[]>([]);
  const [history, setHistory] = useState<any>([]);
  const [undoHistory, setUndoHistory] = useState<any>([]);

  const token = localStorage.getItem('AccessToken');

  const mapAdditionalPropertiesToColumns = (
    additionalProperties: AdditionalProperty[],
  ): CustomColumnType[] => {
    if (additionalProperties) {
      return additionalProperties.map((property) => {
        return {
          accessor: property.name,
          Header: property.name,
          type: property.columnType,
          values: property.values,
        };
      });
    }
    return [];
  };

  const handleUndo = useCallback(() => {
    if (view && stateProject && !isDrawingButtonClicked && project) {
      if (history.length > 1) {
        const cloneHistory = cloneDeep(history);
        const cloneUndoHistory = cloneDeep(undoHistory);

        const lastAction = cloneHistory.pop();
        cloneUndoHistory.push(lastAction);

        const lastActionIndex = cloneHistory.length - 1;

        setHistory(cloneHistory);
        setUndoHistory(cloneUndoHistory);

        ProjectService.updateProject(project.id, cloneHistory[lastActionIndex]).then((resp) => {
          const housesFromResponse = mapHousePropertiesToHouses(resp.data.houses).sort(
            (a: any, b: any) => a.customId - b.customId,
          );
          setStateProject({
            ...stateProject,
            houses: housesFromResponse,
            projectProperties: resp.data.projectProperties,
          });
        });
      }
    }
  }, [view, stateProject, isDrawingButtonClicked, project, history, undoHistory]);

  const handleRedo = useCallback(() => {
    if (view && stateProject && !isDrawingButtonClicked && project) {
      if (undoHistory.length > 0) {
        const cloneHistory = cloneDeep(history);
        const cloneUndoHistory = cloneDeep(undoHistory);

        const lastAction = cloneUndoHistory.pop();
        cloneHistory.push(lastAction);

        const lastActionIndex = cloneHistory.length - 1;

        setHistory(cloneHistory);
        setUndoHistory(cloneUndoHistory);

        ProjectService.updateProject(project.id, cloneHistory[lastActionIndex]).then((resp) => {
          const housesFromResponse = mapHousePropertiesToHouses(resp.data.houses).sort(
            (a: any, b: any) => a.customId - b.customId,
          );
          setStateProject({
            ...stateProject,
            houses: housesFromResponse,
            projectProperties: resp.data.projectProperties,
          });
        });
      }
    }
  }, [view, stateProject, isDrawingButtonClicked, project, history, undoHistory]);

  const handleSave = useCallback(
    (editedProject: ProjectType) => {
      additionalColumns.forEach((column) => {
        if (
          !projectTableColumns.find((baseColumn) => baseColumn.accessor === column.accessor) &&
          column.accessor !== 'type'
        ) {
          if (!editedProject.additionalProperties) {
            editedProject.additionalProperties = [];
          }
          if (
            !editedProject.additionalProperties.find((property) => property.name === column.accessor) &&
            column.accessor !== 'additionalProperties'
          ) {
            editedProject.additionalProperties.push({
              name: column.accessor,
              columnType: column ? column.type : '-1',
              values: column && column.values,
            });
          } else {
            console.log('Already exist');
          }
        }
      });
      editedProject.houses.forEach((house: any) => {
        for (const [key, value] of Object.entries(house)) {
          if (
            key !== 'id' &&
            key !== 'type' &&
            key !== 'houseProperties' &&
            key !== 'editorSettings' &&
            key !== 'imagesMap' &&
            key !== 'polygons' &&
            key !== 'createdAt' &&
            key !== 'updatedAt'
          ) {
            if (!house.houseProperties) {
              house.houseProperties = {};
            }
            house.houseProperties[key] = value;

            delete house[key];
          }
        }
      });
      ProjectService.updateProject(project?.id, editedProject)
        .then((resp) => {
          setStateProject(resp.data);
          if (resp.data.additionalProperties) {
            setAdditionalColumns(mapAdditionalPropertiesToColumns(resp.data.additionalProperties));
          }
        })
        .catch((e) => {
          toast.error(e.message);
        });
    },
    [additionalColumns, project],
  );

  const updateProjectState = (project: ProjectType) => {
    setStateProject({
      ...project,
      houses: mapHousePropertiesToHouses(project.houses).sort((a: any, b: any) => a.customId - b.customId),
    });
    if (project.additionalProperties) {
      setAdditionalColumns(mapAdditionalPropertiesToColumns(project.additionalProperties));
    }
  };

  const enableTool = () => {
    dispatch({ type: TOOL_VIEW_SET, payload: true });
  };

  const disableTool = () => {
    dispatch({ type: TOOL_VIEW_SET, payload: false });
  };

  const fetchProjectByPrimaryId = () => {
    dispatch(ProjectService.getProjectByPrimaryId(id, token ?? ''));
  };

  const fetchProject = () => {
    dispatch(ProjectService.getProject(id));
  };

  return {
    handleRedo,
    handleUndo,
    handleSave,
    enableTool,
    disableTool,
    fetchProjectByPrimaryId,
    fetchProject,
    updateProjectState,
    view,
    stateProject,
    additionalColumns,
    project,
    setStateProject,
    setHistory,
    setAdditionalColumns,
    setUndoHistory,
  };
};
