import React from "react";

const PredefinedColors = ({
	colors,
	onClick,
}: {
	colors: string[];
	onClick: (color: string) => void;
}) => {
	const renderColorSquareBackground = (colorHex: string) =>
		colorHex === "#00000000"
			? { content: "url(/no_fill.png)" }
			: { backgroundColor: colorHex };

	const renderColorSquare = (colorHex: string) => {
		return (
			<div
				style={{
					...renderColorSquareBackground(colorHex),
					...{
						width: "16px",
						height: "16px",
						margin: "0 10px 10px 0",
					},
				}}
				onClick={() => onClick(colorHex)}
			/>
		);
	};

	return (
		<div
			style={{
				width: "210px",
				padding: "10px 0 0 10px",
				display: "flex",
				flexWrap: "wrap",
				position: "relative",
			}}
		>
			{colors.map(color => renderColorSquare(color))}
		</div>
	);
};

export default PredefinedColors;
