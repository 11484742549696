import { FETCH_FAILED, FETCH_STARTED, FETCH_SUCCESSFUL } from '../actions/Actions';
import { AnyAction } from 'redux';
import { FetchState } from 'types/state';

const initialStore: FetchState = {
  isLoading: false,
  error: '',
};

export const fetchReducer = (state = initialStore, action: AnyAction) => {
  switch (action.type) {
    case FETCH_STARTED:
      return {
        ...state,
        isLoading: true,
        error: '',
      };
    case FETCH_SUCCESSFUL:
      return {
        ...state,
        isLoading: false,
        error: '',
      };
    case FETCH_FAILED:
      return {
        ...state,
        isLoading: false,
        error: action.payload,
      };
    default:
      return { ...state };
  }
};
