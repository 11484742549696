import axios from 'axios';
import { SVG_URL } from '../platform/constants/UrlConstants';
import { authHeader } from './AuthHeader';

const saveSvg = (html: string, projectId: string | undefined) => {
  return axios.post(SVG_URL, { html, projectId }, { headers: authHeader() });
};

export default {
  saveSvg,
};
