import { AxiosRequestHeaders } from "axios";

export const authHeader = (): AxiosRequestHeaders => {
	const isNewProjectFlow =
		window.location.href.includes("new-project-flow") ||
		window.location.href.includes("draw");

	const token = isNewProjectFlow
		? localStorage.getItem("AccessToken")
		: localStorage.getItem("token");
	return token ? { Authorization: token } : {};
};
