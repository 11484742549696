import React, { useState } from "react";
import DialogTitle from "@material-ui/core/DialogTitle";
import TextField from "@material-ui/core/TextField";
import { Button, Dialog, DialogContent } from "@material-ui/core";
import DialogActions from "@material-ui/core/DialogActions";

export const IFramePopUp = (props: {
	isPopupOpen: boolean;
	iFrameContent: string;
	handleClose: () => void;
}) => {
	const { isPopupOpen, iFrameContent, handleClose } = props;

	const [iFrameEditedContent, setIFrameEditedContent] =
		useState<string>(iFrameContent);

	return (
		<div>
			<Dialog open={isPopupOpen} aria-labelledby='form-dialog-title' fullWidth>
				<DialogTitle id='form-dialog-title'>IFrame</DialogTitle>
				<DialogContent>
					<TextField
						id='iframe'
						autoFocus
						type='name'
						multiline
						fullWidth
						defaultValue={iFrameEditedContent.trim()}
						onChange={event => setIFrameEditedContent(event.target.value)}
					/>
				</DialogContent>
				<DialogActions>
					<Button onClick={handleClose} color='primary'>
						Close
					</Button>
					<Button
						onClick={() => navigator.clipboard.writeText(iFrameEditedContent)}
						color='primary'
					>
						Copy
					</Button>
				</DialogActions>
			</Dialog>
		</div>
	);
};
