import React, { useState } from 'react';
import LabeledInput from '../../components/form/LabeledInput';
import { FormButton } from '../../components/form/FormElements';
import { FormWrapper, NewProjectWrapper } from './styles';
import ImageUploader from '../../components/ImageUploader/ImageUploader';
import { loadImageDownloadUrl, saveImage } from '../../../persistance/persistence';
import { v4 as uuidv4 } from 'uuid';
import { useHistory } from 'react-router';
import { toast } from 'react-toastify';

const NewProjectForm = (props: {
  onSubmit: (projectName: string, numberOfHouses: number) => void;
  setImageUrl: (url: string) => void;
}) => {
  const [projectName, setProjectName] = useState<string>('');
  const [numberOfHouses, setNumberOfHouses] = useState<number>(0);

  const [projectNameError, setProjectNameError] = useState<string>('');
  const [numberOfHousesError, setNumberOfHousesError] = useState<string>('');

  const [file, setFile] = useState<any>();

  const [disable, setDisable] = useState(true);

  const history = useHistory();

  const buttonSubmit = () => {
    if (projectName !== '' && numberOfHouses > 0 && !disable) {
      return (
        <FormButton
          className="addProject"
          onClick={() => {
            props.onSubmit(projectName, numberOfHouses);
          }}
        >
          Add project
        </FormButton>
      );
    }
    return (
      <FormButton
        disabled
        className="addProject blocked"
        onClick={() => {
          props.onSubmit(projectName, numberOfHouses);
        }}
      >
        Add project
      </FormButton>
    );
  };

  return (
    <>
      <NewProjectWrapper>
        <h1>Create a new project</h1>
        <div style={{ position: 'relative' }}>
          <FormWrapper>
            <LabeledInput
              labelClass="projectName"
              block
              labelText={'Project name'}
              value={projectName}
              onChange={(e) => setProjectName(e.target.value)}
              setErrorMessage={setProjectNameError}
              className="project-name"
              placeholder="enter project name...."
              errorText="errorText"
            />
            <LabeledInput
              labelClass="numberOfUnits"
              value={numberOfHouses}
              block
              labelText={'Number of units'}
              onChange={(e) => setNumberOfHouses(e.target.value)}
              type="number"
              setErrorMessage={setNumberOfHousesError}
              className="number-of-units"
              placeholder="999"
              errorText="errorText"
            />
            <ImageUploader
              noMargin
              text={`Max file size: 5 mb; minimal resolution: 1920 * 1020 px; extension: image/jpeg,image/png `}
              onChange={async (e: any) => {
                toast.info('Image is uploading...');
                const file = e.target.files[0];
                const url = uuidv4();
                setFile(file);
                await saveImage(url, file);
                const ariaPreViewImage = await loadImageDownloadUrl(url);
                props.setImageUrl(ariaPreViewImage || '');
                setDisable(false);
                toast.success('Image uploaded');
              }}
            />
          </FormWrapper>
        </div>
        <div>
          {file && (
            <img
              style={{
                width: '30vw',
                height: 'auto',
              }}
              src={URL.createObjectURL(file)}
              // src={filePath}
            />
          )}
        </div>
        {buttonSubmit()}
      </NewProjectWrapper>
    </>
  );
};

export default NewProjectForm;
