import styled from "styled-components";

export const PublishSVG = styled.button`
	width: 132px;
	height: 56px;
	background-color: #007bff;
	color: #fff;
	border: none;
	cursor: pointer;
	margin-left: 20px;
	margin-right: 50px;
	display: flex;
	justify-content: center;
	align-items: center;
	font-size: 16px;
	border-radius: 5px;

	&:hover {
		color: #000;
		background-color: #fff;
	}
`;
export const PublishButtons = styled.div`
	bottom: 30px;
	right: 60px;
	position: fixed;
	display: flex;
`;

export const PublishIframe = styled.button`
	width: 132px;
	height: 56px;
	background-color: #007bff;
	color: #fff;
	border: none;
	cursor: pointer;
	display: flex;
	justify-content: center;
	align-items: center;
	font-size: 16px;
	border-radius: 5px;

	&:hover {
		color: #000;
		background-color: #fff;
	}
`;
