import React, { useEffect, useState } from 'react';
import { FaBars } from 'react-icons/fa';
import {
  MobileIcon,
  Nav,
  NavBarContainer,
  NavBtn,
  NavBtnLink,
  NavButton,
  NavItem,
  NavLinks,
  NavLogo,
  NavMenu,
} from './NavBarElements';
import Sidebar from '../SideBar';
import { HOME_PATH, LOGIN_PATH, NEW_USER_FLOW_PATH } from '../../constants/PathConstants';
import { animateScroll as scroll } from 'react-scroll';
import { useHistory } from 'react-router-dom';

const NavBar = () => {
  const history = useHistory();

  const [scrollNav, setScrollNav] = useState(false);

  const changeNav = () => {
    if (window.scrollY >= 80) {
      setScrollNav(true);
    } else {
      setScrollNav(false);
    }
  };

  useEffect(() => {
    window.addEventListener('scroll', changeNav);
  }, []);

  const [isOpen, setIsOpen] = useState<boolean>(false);

  const toggle = () => {
    setIsOpen(!isOpen);
  };

  const toggleHome = () => {
    scroll.scrollToTop();
  };

  return (
    <>
      <Sidebar isOpen={isOpen} toggle={toggle} />
      <Nav scrollNav={scrollNav}>
        <NavBarContainer>
          <NavLogo to={HOME_PATH} onClick={toggleHome}>
            MapTool
          </NavLogo>
          <MobileIcon onClick={toggle}>
            <FaBars />
          </MobileIcon>
          <NavMenu>
            <NavItem>
              <NavLinks to="about" smooth={true} duration={800} spy={true} offset={-80}>
                Welcome
              </NavLinks>
            </NavItem>
            <NavItem>
              <NavLinks to="usage" smooth={true} duration={800} spy={true} offset={-80}>
                Cases
              </NavLinks>
            </NavItem>
            <NavItem>
              <NavLinks to="publish" smooth={true} duration={800} spy={true} offset={-80}>
                Contact
              </NavLinks>
            </NavItem>
            <NavItem>
              <NavLinks to="language" smooth={true} duration={800} spy={true} offset={-80}>
                Language
              </NavLinks>
            </NavItem>
            <NavItem>
              <NavButton
                onClick={() => {
                  history.push(NEW_USER_FLOW_PATH);
                }}
              >
                Sign Up
              </NavButton>
            </NavItem>
          </NavMenu>

          <NavBtn>
            <NavBtnLink to={LOGIN_PATH}>My Account</NavBtnLink>
          </NavBtn>
        </NavBarContainer>
      </Nav>
    </>
  );
};

export default NavBar;
