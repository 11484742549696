import axios from 'axios';
import { Dispatch } from 'redux';
import { AUTH_URL } from '../platform/constants/UrlConstants';
import { logoutUserAction } from '../platform/redux/actions/UserActions';
import { authHeader } from './AuthHeader';

const login = (username: string, password: string): Promise<any> => {
  // dispatch(fetchStarted());
  return axios.post(AUTH_URL + 'login', { username, password });
  // .then(resp => {
  // 	// dispatch(fetchSuccessful());
  // 	const user = resp.data.user;
  // 	const token = resp.data.token;
  // 	dispatch(loginUserAction(user));
  // 	localStorage.setItem("token", token);
  // })
  // .catch(err => dispatch(fetchFailed(err.response.data.message)));
};

const loginToken = (username: string, password: string) => {
  axios
    .post(AUTH_URL + 'login', { username, password })
    .then((resp) => {
      const token = resp.data.token;
      localStorage.setItem('token', token);
    })
    .catch((err) => alert(err.response.data.message));
};

const register = (username: string, email: string, password: string): Promise<any> => {
  return axios.post(AUTH_URL + 'register', { username, email, password });
};

const logout = () => (dispatch: Dispatch) => {
  localStorage.removeItem('token');
  dispatch(logoutUserAction());
};

const verifyToken = () => (dispatch: Dispatch) => {
  return axios.get(AUTH_URL + 'token', { headers: authHeader() }).catch(() => {
    localStorage.removeItem('token');
    dispatch(logoutUserAction());
  });
};

export default {
  login,
  loginToken,
  register,
  verifyToken,
  logout,
};
