import reactImg3 from "../../components/images/fill_Form.svg";
import reactImg4 from "../../components/images/urban_design.svg";
import reactImg5 from "../../components/images/code.svg";

export const homeObjOne = {
	id: "about",
	lightBg: false,
	lightText: false,
	lightTextDescription: true,
	topLine: "Create SVGs for your configurators easily",
	headLine: "with the Map Tool",
	description: "Quickly and easily draw available lot using the drawing tool",
	buttonLabel: "Try it for free >",
	imgStart: false,
	img: reactImg4,
	alt: "Drawing",
	dark: true,
	primary: true,
	darkText: false,
};

export const homeObjTwo = {
	id: "usage",
	lightBg: true,
	lightText: false,
	lightTextDescription: false,
	topLine: "Usage is very easy, use these four steps to ",
	headLine: "create your first project",
	description: "Get to know the app easily in these 5 simple steps",
	buttonLabel: "Try it for free >",
	imgStart: true,
	img: reactImg3,
	alt: "Steps",
	dark: true,
	primary: true,
	darkText: false,
};

export const homeObjThree = {
	id: "publish",
	lightBg: false,
	lightText: false,
	lightTextDescription: true,
	topLine: "Publish your drawings",
	headLine: "as SVG or as an Iframe",
	description: "and import it into your projects",
	buttonLabel: "Try it for free >",
	imgStart: false,
	img: reactImg5,
	alt: "Publish",
	dark: true,
	primary: true,
	darkText: false,
};
