import styled from 'styled-components';
import { Link } from 'react-router-dom';

export const FormWrap = styled.div`
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;

  @media screen and (max-width: 400px) {
    height: 80%;
  }
`;
export const Icon = styled(Link)`
  margin-left: 52px;
  margin-top: 32px;
  text-decoration: none;
  color: #fff;
  font-weight: 200;
  font-size: 12px;

  @media screen and (max-width: 480px) {
    margin-left: 16px;
    margin-top: 8px;
  }
`;

export const FormContent = styled.div`
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;

  @media screen and (max-width: 480px) {
    padding: 10px;
  }
`;

export const Form = styled.div`
  max-width: 500px;
  height: auto;
  width: 100%;
  // z-index: 1;
  display: grid;
  margin: 0 auto;
  border-radius: 4px;
  /* box-shadow: 0 1px 1px #52525291; */
  // position: relative;
  // top: 100px;

  input {
    border: 1px solid #cecece;
    border-radius: 0;
  }

  @media screen and (max-width: 400px) {
    padding: 32px 32px;
  }
`;

export const FormH1 = styled.div`
  margin-bottom: 40px;
  color: #000000;
  font-size: 26px;
  font-weight: 400;
  text-align: center;
  justify-self: end;
  width: 315px;
  font-family: 'Galano Grotesque';
`;

export const FormLabel = styled.label`
  margin-bottom: 8px;
  font-size: 16px;
  color: #000000;
  font-family: 'Galano Grotesque';
`;

export const FormInput = styled.input<{ validationError: boolean }>`
  padding: 16px 16px;
  border: ${(props) => !props.validationError && 'none'};
  border-color: ${(props) => (props.validationError ? 'red' : 'none')};
  border-radius: 8px;
  width: 315px;
  font-family: 'Galano Grotesque';
`;

export const FormButton = styled.button`
  background: #2e7dcb;
  cursor: pointer !important;
  padding: 16px 0;
  border-radius: 10px !important;
  border: none;
  color: #ffffff;
  width: 214px;
  justify-self: end;
  margin-right: 50px;

  &:hover {
    color: #000;
  }
`;
