import { useEffect } from "react";
import { useDispatch } from "react-redux";

export const useReduxFetch = (itemToFetch: any, itemToFetchFn: any) => {
	const dispatch = useDispatch();

	useEffect(() => {
		// if (!itemToFetch) {
		dispatch(itemToFetchFn);
		// }
	}, []);
};
