import styled from "styled-components";
import addRow from "../../../assets/images/icons/project/add_row.svg";
import addColumn from "../../../assets/images/icons/project/add_column.svg";

export const ProjectToggleWrapper = styled.div`
	position: absolute;
	right: 100px;
`;

export const AddRow = styled.img.attrs({ src: addRow })``;

export const AddColumn = styled.img.attrs({ src: addColumn })``;

export const HouseViewWrapper = styled.div`
	display: flex;

	h2 {
		font-size: 24px;
		font-family: Silka;
	}

	padding-bottom: 50px;
`;
