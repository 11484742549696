import React, { Fragment, useCallback, useEffect } from 'react';
import { useSelector } from 'react-redux';
import ProjectService from 'services/ProjectService';
import { useReduxFetch } from 'platform/hooks/useReduxFetch';
import { projectTableColumns } from 'platform/components/Table/house-table/HouseTable';
import Tool from 'tool/components/Tool';
import { mapHousePropertiesToHouses } from 'platform/utils/HouseUtils';
import ProjectTable from 'platform/components/Table/ProjectTable';
import UserService from 'services/UserService';
import { getUser } from 'platform/redux/selectors/user';
import { useProjectActions } from 'platform/hooks/useProjectActions';
import { ProjectTableWrapper } from './styles';
import { Project as ProjectType } from 'types/Project';
import { House } from 'types/House';

const Project = () => {
  const user = useSelector(getUser);

  useReduxFetch(user, UserService.getUser());

  const {
    handleUndo,
    handleRedo,
    handleSave,
    updateProjectState,
    fetchProject,
    fetchProjectByPrimaryId,
    enableTool,
    disableTool,
    stateProject,
    view,
    additionalColumns,
    project,
    setStateProject,
    setHistory,
    setAdditionalColumns,
    setUndoHistory,
  } = useProjectActions();

  const isDrawingPage = window.location.href.includes('draw');

  const updateAdditionalColumns = useCallback(
    (stateProject: ProjectType) => {
      additionalColumns.forEach((column) => {
        if (
          !projectTableColumns.find((baseColumn) => baseColumn.accessor === column.accessor) &&
          column.accessor !== 'type' &&
          project
        ) {
          if (stateProject.additionalProperties) {
            if (!stateProject.additionalProperties) {
              project.additionalProperties = [];
            }
            if (
              !stateProject.additionalProperties.find((property) => property.name === column.accessor) &&
              column.accessor !== 'additionalProperties'
            ) {
              stateProject.additionalProperties.push({
                name: column.accessor,
                columnType: column ? column.type : '-1',
                values: column && column.values,
              });
            } else {
              console.log('Already exist');
            }
          }
        }
      });
    },
    [project, additionalColumns],
  );

  const updateHouses = (project: ProjectType) => {
    project.houses.forEach((house: House) => {
      Object.entries(house).forEach(([key, value]) => {
        if (
          key !== 'id' &&
          key !== 'type' &&
          key !== 'houseProperties' &&
          key !== 'editorSettings' &&
          key !== 'imagesMap' &&
          key !== 'polygons' &&
          key !== 'createdAt' &&
          key !== 'updatedAt'
        ) {
          if (!house.houseProperties) house.houseProperties = {};

          // @ts-ignore
          house.houseProperties[key] = value;

          // @ts-ignore
          delete house[key];
        }
      });
    });
  };

  const updateToolProjectData = (projectFromTool: ProjectType) => {
    if (!stateProject) return;

    updateAdditionalColumns(stateProject);

    updateHouses(projectFromTool);

    if (projectFromTool.aerialViewImage === '') {
      projectFromTool.aerialViewImage = stateProject.aerialViewImage;
    }

    ProjectService.updateProject(project?.id, projectFromTool)
      .then((resp) => {
        const housesFromResponse = mapHousePropertiesToHouses(resp.data.houses).sort(
          (a: any, b: any) => a.customId - b.customId,
        );
        setStateProject({
          ...stateProject,
          houses: housesFromResponse,
          projectProperties: resp.data.projectProperties,
        });
      })
      .then(() => {
        setHistory((prev: any) => {
          return [...prev, projectFromTool];
        });
      });
  };

  useEffect(() => {
    document.body.style.background = '#EEEEEE';
    if (isDrawingPage) {
      enableTool();
      fetchProjectByPrimaryId();
    } else fetchProject();

    if (project) updateProjectState(project);
  }, []);

  useEffect(() => {
    if (!view) {
      setHistory([]);
      setUndoHistory([]);
    }
  }, [view]);

  useEffect(() => {
    if (project) updateProjectState(project);
  }, [project]);

  useEffect(() => {
    if (project) {
      if (stateProject && stateProject?.id !== project.id) {
        setStateProject(project);
      }
    }
  }, [stateProject]);

  if (!stateProject) return <Fragment></Fragment>;

  if (!view)
    return (
      <ProjectTableWrapper>
        <ProjectTable
          project={stateProject}
          additionalColumns={additionalColumns}
          setAdditionalColumns={setAdditionalColumns}
          handleSave={handleSave}
          buttons
        />
      </ProjectTableWrapper>
    );

  return (
    <Tool
      handleRedo={handleRedo}
      handleUndo={handleUndo}
      isDrawingPage={isDrawingPage}
      project={stateProject}
      updateProjectData={updateToolProjectData}
      publishButtons
      setToolView={(toolView: boolean) => disableTool()}
      tableButton
      fullMode
    />
  );
};

export default Project;
