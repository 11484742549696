import React, { memo, useContext } from "react";
import { ToolboxContext } from "tool/components/context/ToolboxContext";
import { Point, PolygonType } from "types/PolygonType";
import { findPolygonCenter } from "tool/components/drawing-view/map/utils/MapBuildUtils";
import { HouseContext } from "tool/components/context/HouseContext";
import {
	SVGCircle,
	SVGMagneticCircle,
	MagneticSVGPolygon,
	SVGPolygon,
	IdCircle,
	IdText,
} from "./styles";

const Polygon = (props: {
	polygon: PolygonType;
	fillColor: string;
	fillOpacity: number;
	strokeColor: string;
	strokeOpacity: number;
	strokeWidth: number;
	cornersRadius: number;
	isDrawingPath?: boolean | null | undefined;
	currentScale: number;
	isNewPath: boolean;
	hoveredPlot: PolygonType | null;
	houses?: any;
	savedPolygons?: any;
	changeHouseForPolygon?: any;
	setMenuHouses?: any;
	houseId?: any;
	menuHouses?: boolean;
	houseCurrent?: any;
	setSelectedPlot?: any;
	selectedPlot: any;
	isContextMenuButtonClicked?: any;
	setIsContextMenuButtonClicked?: any;
	isShiftHold: boolean;
}) => {
	const {
		polygon,
		fillColor,
		fillOpacity,
		strokeColor,
		strokeOpacity,
		strokeWidth,
		cornersRadius,
		currentScale,
		isDrawingPath,
		isNewPath,
		hoveredPlot,
		houses,
		savedPolygons,
		changeHouseForPolygon,
		setMenuHouses,
		houseId,
		menuHouses,
		houseCurrent,
		setSelectedPlot,
		selectedPlot,
		isShiftHold,
	} = props;

	const {
		isMovingPolygon,
		setIsDrawingButtonClicked,
		isPointerButtonClicked,
		isDrawGuideLineButtonClicked,
		isDrawingButtonClicked,
		isEditButtonClicked,
		isContextMenuButtonClicked,
		setIsContextMenuButtonClicked,
	} = useContext(ToolboxContext);

	const center: Point = findPolygonCenter(polygon);
	const magneticPolygonScale = 1 + 0.07 / currentScale;
	const scaledCenterX = center.x - magneticPolygonScale * center.x;
	const scaledCenterY = center.y - magneticPolygonScale * center.y;
	const { house, polygonId, setHouse, newPlot } = useContext(HouseContext);
	const disableEvents = polygon.id === "x-polygon";

	function leftClick(e: any) {
		if (polygonId !== polygon.id && isPointerButtonClicked) {
			setIsDrawingButtonClicked(false);
			setSelectedPlot(polygon.id);
			setHouse(houseCurrent);
		}

		if (
			e.target.className.baseVal === "changeHouseId" &&
			isPointerButtonClicked
		) {
			setMenuHouses(true);
			setIsContextMenuButtonClicked(true);
		}
	}

	function rightClick(e: any) {
		if (isPointerButtonClicked && selectedPlot) {
			setMenuHouses(true);
		}
	}

	return (
		<g>
			{isDrawingPath && (
				<MagneticSVGPolygon
					transform={`matrix(${magneticPolygonScale}, 0, 0, ${magneticPolygonScale}, ${scaledCenterX}, ${scaledCenterY})`}
					style={{ pointerEvents: disableEvents ? "none" : "auto" }}
					id={"magnetic-polygon;" + polygon.id}
					points={Array.from(polygon.points).reduce(
						(str: string, point: Point) => str + " " + point.x + "," + point.y,
						""
					)}
				/>
			)}

			{/* Polygon*/}
			<SVGPolygon
				id={polygon.id}
				$fillOpacity={fillOpacity}
				stroke={polygon.id === polygonId ? "red" : "white"}
				$strokeOpacity={strokeOpacity}
				$strokeWidth={polygon.id === polygonId ? "2px" : "1px"}
				$currentScale={currentScale}
				$isActive={
					polygon.id ===
						(house && house.polygons.length > 0 ? polygonId : undefined) ||
					polygon.id === "x-polygon" ||
					polygon.id === polygonId
				}
				style={{ pointerEvents: disableEvents ? "none" : "auto" }}
				fill={fillColor}
				points={Array.from(polygon.points).reduce(
					(str: string, point: Point) => str + " " + point.x + "," + point.y,
					""
				)}
				onContextMenu={e =>
					!isDrawGuideLineButtonClicked &&
					!isDrawingButtonClicked &&
					rightClick(e)
				}
			/>

			{/* Points*/}
			{!isMovingPolygon &&
			(polygon.id === "x-polygon" || polygon.id === polygonId)
				? Array.from(polygon.points).map(
						(point: Point, i) =>
							(!props.isDrawingPath || i < polygon.points.length - 1) && (
								<SVGCircle
									style={{
										pointerEvents: disableEvents && i > 0 ? "none" : "auto",
										// stroke: isNewPath ? "green" : "",
									}}
									key={i}
									id={"edit-circle"}
									cx={point.x}
									cy={point.y}
									$sizer={2}
									r={cornersRadius / currentScale}
									fill={"#5288f5"}
									data-polygonId={polygon.id}
									$currentScale={currentScale}
								/>
							)
				  )
				: (isDrawingButtonClicked || isEditButtonClicked) &&
				  !isShiftHold &&
				  Array.from(polygon.points).map((point: Point, i) => (
						<SVGMagneticCircle
							key={i}
							cx={point.x}
							cy={point.y}
							data-polygonId={polygon.id}
							$currentScale={currentScale}
							// r={props.cornersRadius}
							// fill={props.strokeColor}
						/>
				  ))}

			{/* White circle*/}
			{!isMovingPolygon && !newPlot && (
				<IdCircle
					id={"center"}
					$disablePointerEvents={disableEvents}
					$isMove={
						!isDrawGuideLineButtonClicked &&
						!isDrawingButtonClicked &&
						polygon.id === polygonId
					}
					$currentScale={currentScale}
					$sizer={4.1}
					key={polygon.points.length}
					cx={center.x}
					cy={center.y}
					r={cornersRadius / currentScale}
					fill={fillColor}
					data-polygonId={polygon.id}
					onClick={e =>
						!isDrawGuideLineButtonClicked &&
						!isDrawingButtonClicked &&
						leftClick(e)
					}
				/>
			)}

			{/* Text in white circle*/}
			{!isMovingPolygon && !newPlot && (
				<>
					<circle
						onClick={e =>
							!isDrawGuideLineButtonClicked &&
							!isDrawingButtonClicked &&
							leftClick(e)
						}
						cx={center.x + 4.5 / currentScale}
						cy={center.y - 8 / currentScale}
						id={`${polygon.id}-houseId`}
						className={"changeHouseId"}
						r={3.5 / currentScale}
						style={{
							fill: "orange",
							border: isContextMenuButtonClicked ? "black solid 1px" : "",
							strokeWidth: 0.5 / currentScale,
							position: "absolute",
							cursor:
								isDrawingButtonClicked ||
								isDrawGuideLineButtonClicked ||
								polygon.id !== polygonId
									? "default"
									: "pointer",
						}}
					/>
					<text
						onClick={e =>
							!isDrawGuideLineButtonClicked &&
							!isDrawingButtonClicked &&
							leftClick(e)
						}
						x={center.x + 2 / currentScale}
						y={center.y - 7.8 / currentScale}
						id={`${polygon.id}-houseId`}
						className={"changeHouseId"}
						fontSize={7 / currentScale}
						fontFamily="'Silka', sans-serif"
						fill='black'
						style={{
							position: "absolute",
							fontWeight: "bold",
							cursor:
								isDrawingButtonClicked ||
								isDrawGuideLineButtonClicked ||
								polygon.id !== polygonId
									? "default"
									: "pointer",
						}}
					>
						...
					</text>
					<IdText
						x={
							houseId.length > 1
								? center.x - 4.7 / currentScale
								: center.x - 4 / currentScale
						}
						y={center.y + 2 / currentScale}
						onClick={e =>
							!isDrawGuideLineButtonClicked &&
							!isDrawingButtonClicked &&
							leftClick(e)
						}
						id={`houseId`}
						data-polygonId={polygon.id}
						$currentScale={currentScale}
						$reduceFontSize={houseId.length > 1}
						$isMove={
							!isDrawGuideLineButtonClicked &&
							!isDrawingButtonClicked &&
							polygon.id === polygonId
						}
					>
						{houseId}
					</IdText>
				</>
			)}
		</g>
	);
};

export default memo(Polygon);
