import React from "react";
import {
	SidebarContainer,
	Icon,
	CloseIcon,
	SidebarWrapper,
	SidebarMenu,
	SidebarLink,
	SidebarBtnWrap,
	SidebarRoute,
} from "./SidebarElements";

const Sidebar = (props: { isOpen: boolean; toggle: (e: any) => void }) => {
	return (
		<SidebarContainer isOpen={props.isOpen} onClick={props.toggle}>
			<Icon onClick={props.toggle}>
				<CloseIcon />
			</Icon>
			<SidebarWrapper>
				<SidebarMenu>
					<SidebarLink
						to='about'
						// onClick={props.toggle}
					>
						About
					</SidebarLink>
					<SidebarLink
						to='cases'
						// onClick={props.toggle}
					>
						Cases
					</SidebarLink>
					<SidebarLink
						to='contact'
						// onClick={props.toggle}
					>
						Contact
					</SidebarLink>
					<SidebarLink
						to='language'
						// onClick={props.toggle}
					>
						Language
					</SidebarLink>
					<SidebarLink
						to='signup'
						// onClick={props.toggle}
					>
						Account
					</SidebarLink>
				</SidebarMenu>
				<SidebarBtnWrap>
					<SidebarRoute to='/signin'>Sign Up</SidebarRoute>
				</SidebarBtnWrap>
			</SidebarWrapper>
		</SidebarContainer>
	);
};

export default Sidebar;
