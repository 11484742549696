import React from "react";
import { UserInfoWrapper } from "./styles";
import userIcon from "../../../assets/images/icons/sidebar/user.svg";
import { ImageSideBar } from "./SideBar";

const UserInfo = (props: any) => {
	return (
		<UserInfoWrapper>
			<div style={{ display: "flex", alignItems: "center", marginTop: 20 }}>
				<ImageSideBar className='user-icon' src={userIcon} />
				<div>
					<p>Woonmondule</p>
					<p>{props.user.username}</p>
				</div>
			</div>
		</UserInfoWrapper>
	);
};

export default UserInfo;
