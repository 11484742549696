import styled from "styled-components";
import { Link as LinkScroll } from "react-scroll";
import { Link as LinkRouter } from "react-router-dom";
import { FaTimes } from "react-icons/fa";

// interface SidebarLinkProps extends React.HTMLAttributes<HTMLElement> {
//   linkScroll: Component
// }

export const SidebarContainer = styled.aside<{ isOpen: boolean }>`
	position: fixed;
	z-index: 999;
	width: 100%;
	height: 100%;
	background: #3392ff;
	display: none;
	align-items: center;
	top: 0;
	left: 0;
	transition: 0.3s ease-in-out;
	opacity: ${props => (props.isOpen ? "100%" : "0")};
	top: ${props => (props.isOpen ? "0" : "-100%")};

	@media screen and (max-width: 768px) {
		display: grid;
	}
`;

export const CloseIcon = styled(FaTimes)`
	color: #fff;
`;

export const Icon = styled.div`
	position: absolute;
	top: 1.2rem;
	right: 1.5rem;
	background: transparent;
	font-size: 2rem;
	cursor: pointer;
`;

export const SidebarWrapper = styled.div`
	color: #fff;
`;

export const SidebarMenu = styled.div`
	display: grid;
	grid-template-columns: 1fr;
	grid-template-rows: repeat(6, 80px);
	text-align: center;

	@media screen and (max-width: 768px) {
		grid-template-rows: repeat (6, 60px);
	}
`;

export const SidebarLink = styled(LinkScroll)`
	display: flex;
	align-items: center;
	justify-content: center;
	font-size: 1.5rem;
	text-decoration: none;
	list-style: none;
	transition: 0s ease-in-out;
	text-decoration: none;
	color: #fff;
	cursor: pointer;

	&:hover {
		color: #01bf71;
		transition: 0.2s ease-in-out;
	}
`;

export const SidebarBtnWrap = styled.div`
	display: flex;
	justify-content: center;
`;

export const SidebarRoute = styled(LinkRouter)`
	border-radius: 50px;
	background: #01fb71;
	white-space: nowrap;
	padding: 16px 64px;
	color: #010606;
	font-size: 16;
	outline: none;
	border: none;
	cursor: pointer;
	transition: all 0.2s ease-in-out;
	text-decoration: none;

	&:hover {
		transition: all 0.2s ease-in-out;
		background: #fff;
		color: #010606;
	}
`;
