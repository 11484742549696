import React, { useEffect, useState } from 'react';
import { v4 as uuidv4 } from 'uuid';
import { loadImageDownloadUrl, saveImage } from '../../../persistance/persistence';
import HouseTable from './house-table/HouseTable';
import TypeTable, { CustomColumnType } from './type-table/TypeTable';
import ProjectToggle from '../ProjectToggle';
import { BackButton, TableButtonNext } from './TableElements';
import * as AiIcons from 'react-icons/ai';
import { mapHousePropertiesToHouses } from '../../utils/HouseUtils';
import { Project as ProjectType, Project } from '../../../types/Project';
import { HouseViewWrapper, ProjectToggleWrapper } from './styles';
import { useDispatch, useSelector } from 'react-redux';
import HouseService from '../../../services/HouseService';
import { toast } from 'react-toastify';
import { getProject } from 'platform/redux/selectors/project';

export type ProjectTableProps = {
  project: Project;
  additionalColumns?: CustomColumnType[];
  setAdditionalColumns?: (columns: any[]) => void;
  handleSave: (project: Project) => void;
  setIsToolView?: any;
  buttons?: boolean;
};

const ProjectTable = ({
  project,
  additionalColumns,
  setAdditionalColumns,
  handleSave,
  buttons,
  setIsToolView,
}: ProjectTableProps) => {
  const hiddenFileInput = React.useRef(null);
  const dispatch = useDispatch();

  const [houseIdsToDelete, setHouseIdsToDelete] = useState<string[]>([]);
  const projectGlobal = useSelector(getProject);

  const [isProjectView, setProjectView] = useState<boolean>(true);

  const [file, setFile] = useState<any>();
  const [stateProject, setStateProject] = useState<Project>(project || {});
  const [houseData, setHouseData] = useState<any>(project && project.houses);

  useEffect(() => {
    houseIdsToDelete.forEach((id) => {
      HouseService.deleteHouse(id)
        .then(() => toast.success('House deleted'))
        .catch((e) => {
          toast.error(e.message);
        });
    });
    const updatedHouses = stateProject.houses.filter((house: any) => !houseIdsToDelete.includes(house.id));
    if (houseIdsToDelete && houseIdsToDelete.length !== 0) {
      setStateProject({
        ...stateProject,
        houses: updatedHouses,
      });
      setHouseData(updatedHouses);
    }
  }, [houseIdsToDelete]);

  useEffect(() => {
    if (project && project.houses) {
      project.houses = mapHousePropertiesToHouses(project.houses);
      setStateProject({
        ...stateProject,
        houses: project.houses,
        name: project.name,
        aerialViewImage: project.aerialViewImage,
      });
      setHouseData(project.houses);
    }
  }, []);

  useEffect(() => {
    if (project && project.houses) {
      project.houses = mapHousePropertiesToHouses(project.houses);
      setStateProject({
        ...stateProject,
        houses: project.houses,
        name: project.name,
        aerialViewImage: project.aerialViewImage,
      });
      setHouseData(project.houses);
    }
  }, [project]);

  useEffect(() => {
    if (houseIdsToDelete.length > 0) {
      setHouseIdsToDelete([]);
    }
    // if(!compareArrays(houseData,project.houses)){
    // 	console.log("save happened")
    // 	handleSave({ ...stateProject, houses: houseData })
    // }
  }, [houseData]);
  useEffect(() => {
    if (projectGlobal) {
      if (stateProject.id !== projectGlobal.id) {
        setStateProject(projectGlobal);
      }
    }
  }, [stateProject]);
  const reloadPage = () => {
    window.location.reload();
  };

  return (
    <div style={{ position: 'relative', padding: '50px 70px', width: '100%' }}>
      <ProjectToggleWrapper>
        <ProjectToggle isProjectView={isProjectView} setIsProjectView={setProjectView} />
      </ProjectToggleWrapper>
      {isProjectView ? (
        stateProject && (
          <>
            <HouseViewWrapper>
              <div>
                <h2>Project name: {stateProject.name}</h2>
              </div>
              <input
                ref={hiddenFileInput}
                style={{ display: 'none' }}
                type="file"
                onChange={async (event: any) => {
                  const url = uuidv4();
                  await saveImage(url, event.target.files[0]);
                  const newImageUrl = await loadImageDownloadUrl(url);
                  if (newImageUrl) {
                    setStateProject({
                      ...stateProject,
                      aerialViewImage: newImageUrl,
                    });
                    setFile(event.target.files[0]);
                    project.aerialViewImage = newImageUrl;
                  }
                }}
              />
            </HouseViewWrapper>
            <HouseTable
              data={houseData}
              setData={setHouseData}
              additionalColumns={additionalColumns}
              setAdditionalColumns={setAdditionalColumns}
              paginationEnabled
              searchable
              sortable
              editable
              onDelete={(ids: string[]) => {
                setHouseIdsToDelete(ids);
              }}
            />
            {buttons ? (
              <div
                style={{
                  display: 'flex',
                  justifyContent: 'end',
                }}
              >
                <BackButton
                  onClick={async () => {
                    await handleSave({ ...stateProject, houses: houseData });
                  }}
                >
                  Save
                </BackButton>
              </div>
            ) : (
              <TableButtonNext
                onClick={() => {
                  handleSave({ ...stateProject, houses: houseData });
                }}
              >
                Next
                <AiIcons.AiOutlineArrowRight />
              </TableButtonNext>
            )}
          </>
        )
      ) : (
        <div style={{ marginTop: 100 }}>
          <TypeTable />
        </div>
      )}
    </div>
  );
};

export default ProjectTable;
