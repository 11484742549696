import React, { useEffect, useState } from "react";
import Table, { FeatureProps } from "../index";
import TypeService from "../../../../services/TypeService";
import { CustomColumnType } from "../type-table/TypeTable";
import { Property, Type } from "../../../../types/Type";
import Modal from "react-modal";
import { toast } from "react-toastify";

export enum ColumnType {
	NUMBER,
	SELECT,
	MONEY,
}

export enum Orientation {
	E,
	W,
	S,
	N,
	NE,
	SE,
	SW,
	NW,
}

export enum Status {
	Available,
	Offer,
	Sold,
}

export const projectTableColumns = [
	{
		Header: "internalId",
		accessor: "id",
	},
	{
		Header: "Id",
		accessor: "customId",
	},
	{
		Header: "Type",
		accessor: "type",
		type: ColumnType.SELECT,
	},
	{
		Header: "Label",
		accessor: "label",
	},
	{
		Header: "Status",
		accessor: "status",
		type: ColumnType.SELECT,
		values: Object.keys(Status).filter((key: any) => !isNaN(Number(Status[key]))),
	},
	{
		Header: "House Area",
		accessor: "houseArea",
		type: ColumnType.NUMBER,
	},
	{
		Header: "Room count",
		accessor: "roomsQuantity",
		type: ColumnType.NUMBER,
	},
	{
		Header: "Orientation",
		accessor: "orientation",
		type: ColumnType.SELECT,
		values: Object.keys(Orientation).filter(
			(key: any) => !isNaN(Number(Orientation[key]))
		),
	},
	{
		Header: "Terrace",
		accessor: "terrace",
	},
	{
		Header: "Price",
		accessor: "price",
		type: ColumnType.NUMBER,
	},
	{
		Header: "Action",
		accessor: "delete",
		type: ColumnType.NUMBER,
	},
];

interface ProjectTableProps extends FeatureProps {
	data: any;
	setData?: (data: any) => void;
	additionalColumns?: CustomColumnType[];
	setAdditionalColumns?: (columns: any[]) => void;
	onSave?: (columns: CustomColumnType[]) => void;
	onDelete?: (ids: string[]) => void;
	onGo?: (id: string) => void;
}

const HouseTable = ({
	data,
	setData,
	additionalColumns,
	setAdditionalColumns,
	onSave,
	onDelete,
	...rest
}: ProjectTableProps) => {
	const [currentPage, setCurrentPage] = useState<number>(0);
	const [tableColumns, setTableColumns] =
		useState<CustomColumnType[]>(projectTableColumns);
	const [types, setTypes] = useState<Type[]>([]);

	const [newColumnName, setNewColumnName] = useState<string>("");
	const [newColumnType, setNewColumnType] = useState<number>(-1);
	const [newColumnSelectValues, setNewColumnSelectValues] = useState<string>("");

	const [isNewColumnModalOpen, setIsNewColumnModalOpen] =
		useState<boolean>(false);
	useEffect(() => {}, [tableColumns]);
	useEffect(() => {
		let newColumns: any[] = [];
		if (additionalColumns) {
			const columnsIds = new Set(
				tableColumns.map(tableColumn => tableColumn.accessor)
			);
			newColumns = [
				...tableColumns,
				...additionalColumns.filter(
					newColumn => !columnsIds.has(newColumn.accessor)
				),
			];
			setTableColumns(newColumns);
		}
		setColumnsAndFetchTypesForTypeColumnOnInit(newColumns);
	}, []);

	useEffect(() => {
		if (additionalColumns) {
			const columnsIds = new Set(
				tableColumns.map(tableColumn => tableColumn.accessor)
			);
			const newColumns = [
				...tableColumns,
				...additionalColumns.filter(
					newColumn => !columnsIds.has(newColumn.accessor)
				),
			];
			setTableColumns(newColumns);
		}
	}, [additionalColumns]);

	const setColumnsAndFetchTypesForTypeColumnOnInit = (columns: any) => {
		fetchTypesAndCreateTypeColumn().then(typeColumn => {
			const updatedColumnsWithTypeValues = columns.map((column: any) => {
				if (column.accessor === "type") {
					column.values = typeColumn.values;
				}
				return column;
			});

			setTableColumns([...updatedColumnsWithTypeValues]);
		});
	};

	const fetchTypesAndCreateTypeColumn = () => {
		const typeColumn: CustomColumnType = {
			Header: "Type",
			accessor: "type",
			type: ColumnType.SELECT,
		};
		const allColumns: CustomColumnType[] = [typeColumn, ...projectTableColumns];
		return TypeService.getAllTypes().then(data => {
			setTypes(data);
			typeColumn.values = data.map(type => type.customId);
			data.forEach(type => {
				for (const [typeName, typeProperty] of Object.entries(type)) {
					if (
						typeProperty &&
						!allColumns.find(column => column.Header === typeProperty.customId)
					) {
						const newColumnFromType: CustomColumnType = {
							Header: typeProperty.name,
							accessor: typeProperty.name,
							type: typeProperty.columnType,
						};
						allColumns.push(newColumnFromType);
					}
				}
			});
			return typeColumn;
		});
	};

	const fillRowWithTypePredefinedValues = (rowIndex: string, value: any) => {
		const typeName = value;
		const type = types.find(type => type.customId === typeName);
		if (type) {
			for (const [typeName, typeProperty] of Object.entries(type.mapProperties)) {
				const property = typeProperty as Property;
				if (!tableColumns.find(column => column.Header === property.name)) {
					const newColumnFromType: CustomColumnType = {
						Header: property.name,
						accessor: property.name,
						type: property.columnType,
						values: property.values,
					};
					if (additionalColumns) {
						setAdditionalColumns &&
							setAdditionalColumns([...additionalColumns, { ...newColumnFromType }]);
					} else {
						setAdditionalColumns && setAdditionalColumns([{ ...newColumnFromType }]);
					}
				}
				updateCellValue(rowIndex, typeName, property.value);
			}
			updateCellValue(rowIndex, "label", type.label);
		}
	};

	const updateData = (rowIndex: string, columnId: string, value: any) => {
		if (columnId === "type") {
			fillRowWithTypePredefinedValues(rowIndex, value);
		}
		updateCellValue(rowIndex, columnId, value);
	};

	const updateCellValue = (rowIndex: string, columnId: string, value: any) => {
		setData &&
			setData((old: any) =>
				old.map((row: any, index: any) => {
					if (index === parseInt(rowIndex)) {
						return {
							...old[rowIndex],
							[columnId]: value,
						};
					}
					return row;
				})
			);
	};

	const getDefaultColumnsIfEmpty = () => {
		if (tableColumns && tableColumns.length === 0) {
			let columns: any[] = [];
			fetchTypesAndCreateTypeColumn().then(typeColumn => {
				columns = projectTableColumns.map((column: any) => {
					if (column.accessor === "type") {
						column.values = typeColumn.values;
					}
					return column;
				});
			});
			return columns;
		}

		return tableColumns;
	};

	const renderModal = () => {
		return (
			<Modal
				isOpen={isNewColumnModalOpen}
				onRequestClose={() => setIsNewColumnModalOpen(false)}
				ariaHideApp={false}
				style={{
					overlay: {
						// backgroundColor: 'papayawhip'
						zIndex: 1000,
					},
					content: {
						width: "400px",
						height: "200px",
						top: "50%",
						left: "50%",
						right: "auto",
						bottom: "auto",
						marginRight: "-50%",
						transform: "translate(-50%, -50%)",
					},
				}}
			>
				<input
					type='text'
					placeholder='column name'
					onChange={e => setNewColumnName(e.target.value)}
				/>
				<select
					value={newColumnType}
					onChange={e => setNewColumnType(parseInt(e.target.value))}
				>
					<option value={-1} selected>
						Text
					</option>
					<option value={0}>Number</option>
					<option value={1}>Select</option>
					<option value={2}>Money</option>
				</select>
				{newColumnType === 1 && (
					<input
						type='text'
						onChange={e => setNewColumnSelectValues(e.target.value)}
					/>
				)}
				<button
					onClick={() => {
						const newColumn: CustomColumnType = {
							Header: newColumnName,
							accessor: newColumnName,
							type: newColumnType,
						};
						if (newColumnType === ColumnType.SELECT) {
							newColumn.values = newColumnSelectValues
								.trim()
								.split(",")
								.map(value => value.replaceAll(/\s/g, ""));
						}
						// setTableColumns((prevState: any[]) => [...prevState, { ...newColumn }]);
						if (additionalColumns) {
							setAdditionalColumns &&
								setAdditionalColumns([...additionalColumns, { ...newColumn }]);
						} else {
							setAdditionalColumns && setAdditionalColumns([{ ...newColumn }]);
						}
						setIsNewColumnModalOpen(false);
					}}
				>
					Add new column
				</button>
			</Modal>
		);
	};

	return (
		<>
			{renderModal()}
			<Table
				columns={getDefaultColumnsIfEmpty()}
				currentPage={currentPage}
				setCurrentPage={setCurrentPage}
				data={data}
				updateData={setData && updateData}
				hiddenColumns={["id"]}
				selectable
				editable
				onAddRow={() => {
					toast.success("House added");
					const incrementedStubCustomId = "id" + (data.length + 1);
					setData &&
						setData((prevState: any[]) => [
							...prevState,
							{ id: incrementedStubCustomId, customId: incrementedStubCustomId },
						]);
				}}
				onAddColumn={() => {
					setIsNewColumnModalOpen(true);
				}}
				onDelete={onDelete}
				{...rest}
			/>
		</>
	);
};

export default HouseTable;
