import { FETCH_FAILED, FETCH_STARTED, FETCH_SUCCESSFUL } from './Actions';

export const fetchStarted = () => ({
  type: FETCH_STARTED,
});

export const fetchSuccessful = () => ({
  type: FETCH_SUCCESSFUL,
});

export const fetchFailed = (error: any) => ({
  type: FETCH_FAILED,
  payload: error,
});
