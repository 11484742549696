import React from "react";
import NewProjectForm from "./NewProjectForm";

const NewProject = (props: {
	onBack: () => void;
	onSubmit: (projectName: string, numberOfHouses: number) => void;
	setImageUrl: (url: string) => void;
}) => {
	return (
		<>
			<NewProjectForm onSubmit={props.onSubmit} setImageUrl={props.setImageUrl} />
		</>
	);
};

export default NewProject;
