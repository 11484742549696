import { storage } from "./firebase";
// import { convertImageMapToJson } from "../utils/imageUtils";

// import { PROJECT_COLLECTION_PATH } from "../constants/constants";

export async function saveImage(docId: string | undefined, file: any) {
	if (!docId) {
		throw new Error(
			"Must provide ID for image! Should equal to ID of document in DB"
		);
	}
	const fileRef = storage.ref().child(docId);
	const { name, size, contentType } = file;
	return await new Promise(function (resolve, reject) {
		fileRef
			.put(file, { customMetadata: { name, size }, contentType })
			.then(resolve, reject);
	});
}

export async function loadImageDownloadUrl(
	docId: string
): Promise<string | null> {
	const fileRef = storage.ref().child(docId);
	try {
		return await fileRef.getDownloadURL();
	} catch (e) {
		// file doesn't exist
		return null;
	}
}

export function deleteImage(filePath: string) {
	if (filePath !== "") {
		const ref = storage.ref();
		ref.child(filePath).delete();
	}
}
