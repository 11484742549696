import React from 'react';
import { Redirect, Route, RouteProps } from 'react-router-dom';
import { LOGIN_PATH } from '../constants/PathConstants';

const PrivateRoute: React.FC<RouteProps> = (props) => {
  // Temporary fix: need to fix redux action
  if (!localStorage.getItem('token')) {
    const renderComponent = () => <Redirect to={{ pathname: LOGIN_PATH }} />;
    return <Route {...props} component={renderComponent} render={undefined} />;
  }
  return <Route {...props} />;
};

export default PrivateRoute;
