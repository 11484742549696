import React from 'react';
import { UploadFormButton } from '../buttons/ButtonElements';

const ImageUploader = ({ text, onChange, noMargin }: any) => {
  const hiddenFileInput = React.useRef(null);

  return (
    <div
      style={{
        display: 'flex',
        alignItems: 'center',
        marginTop: noMargin ? 0 : '157px',
        marginLeft: noMargin ? 0 : '35px',
      }}
    >
      <UploadFormButton
        onClick={() => {
          // @ts-ignore
          hiddenFileInput.current.click();
        }}
      >
        <input ref={hiddenFileInput} style={{ display: 'none' }} type="file" onChange={onChange} />
      </UploadFormButton>
      <p
        style={{
          maxWidth: '300px',
          fontSize: '0.8rem',
          fontFamily: 'Silka, serif',
        }}
      >
        {text}
      </p>
    </div>
  );
};

export default ImageUploader;
