import styled from "styled-components";

export const Container = styled.div`
	// min-height: 100vh;
	min-height: 100vh;
	min-width: 80vw;
	padding-top: 8%;
	// position: fixed;
	// bottom: 0;
	// left: 0;
	// right: 0;
	// top: 0;
	// z-index: 0;
	// width: 100%;
	overflow: hidden;

	.regErrorText {
		right: -300px;
		bottom: 20px;
	}

	.logInErrorText {
		right: -200px;
		bottom: 20px;
	}
`;

export const ContainerLeft = styled.div`
	min-height: 100vh;
	min-width: 80vw;
	overflow: hidden;
	padding-right: 25%;
	display: flex;
`;

export const FullPageContainer = styled.div`
	min-height: 80vh;
	overflow: hidden;
`;
