import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useLocation } from 'react-router-dom';
import AuthService from '../../../services/AuthService';
import { HOME_PATH } from '../../constants/PathConstants';
import { Nav } from './styles';
import UserInfo from './UserInfo';
import projectsIcon from '../../../assets/images/icons/sidebar/projects.svg';
import unitsIcon from '../../../assets/images/icons/sidebar/units.svg';
import drawingIcon from '../../../assets/images/icons/sidebar/drawing.svg';
import publishIcon from '../../../assets/images/icons/sidebar/publish.svg';
import { TOOL_VIEW_SET } from '../../redux/actions/Actions';
import logoIcon from '../../../assets/images/icons/sidebar/logo.svg';
import kLogoIcon from '../../../assets/images/icons/sidebar/k-logo.svg';
import userIcon from '../../../assets/images/icons/sidebar/user.svg';
import styled from 'styled-components';
import { getView } from 'platform/redux/selectors/tool';

type Props = {};
export const ImageSideBar = styled.img`
  filter: invert(100%) sepia(100%) saturate(0%) hue-rotate(87deg) brightness(105%) contrast(101%);
`;
const SideBar = (props: any) => {
  const dispatch = useDispatch();
  const { pathname } = useLocation();
  const view = useSelector(getView);

  return (
    <div style={{ display: 'flex' }}>
      {view ? (
        <Nav style={{ width: 75, alignItems: 'center', paddingTop: 42 }}>
          <img style={{ width: 59, marginBottom: 54 }} src={kLogoIcon} />

          <ImageSideBar style={{ marginBottom: 170 }} className="user-icon" src={userIcon} />
          <Link
            to="/account"
            style={pathname === '/account/' ? { backgroundColor: '#707070' } : {}}
            onClick={() => dispatch({ type: TOOL_VIEW_SET, payload: false })}
          >
            <ImageSideBar src={projectsIcon} />
          </Link>

          <a
            className={pathname.includes('/projects/') ? '' : 'disabled'}
            style={pathname.includes('/projects/') ? { backgroundColor: '#707070' } : {}}
            onClick={() => dispatch({ type: TOOL_VIEW_SET, payload: false })}
          >
            <ImageSideBar src={unitsIcon} />
          </a>
          <a
            className={pathname.includes('/projects/') ? '' : 'disabled'}
            style={{ backgroundColor: '#707070' }}
            onClick={() =>
              pathname.includes('/projects/') && dispatch({ type: TOOL_VIEW_SET, payload: true })
            }
          >
            <img src={drawingIcon} />
          </a>
          <a className="disabled">
            <ImageSideBar src={publishIcon} />
          </a>
          {props.user && (
            <Link
              to={HOME_PATH}
              onClick={() => {
                dispatch(AuthService.logout());
                dispatch({ type: TOOL_VIEW_SET, payload: false });
              }}
              className="logout"
            >
              Log out
            </Link>
          )}
        </Nav>
      ) : (
        <Nav style={{ width: 380 }}>
          <img src={logoIcon} style={{ marginBottom: props.user ? 0 : 180 }} />
          {props.user && <UserInfo style={{ paddingLeft: 30 }} user={props.user} view={view} />}

          <Link
            to="/account"
            className={props.user || 'disabled'}
            style={
              pathname === '/account/'
                ? {
                    backgroundColor: '#707070',
                    paddingLeft: 30,
                  }
                : { paddingLeft: 30 }
            }
            onClick={() => dispatch({ type: TOOL_VIEW_SET, payload: false })}
          >
            <ImageSideBar src={projectsIcon} />
            Projects
          </Link>
          <a
            className={pathname.includes('/projects/') ? '' : 'disabled'}
            style={
              pathname.includes('/projects/') && view === false
                ? {
                    backgroundColor: '#707070',
                    paddingLeft: 30,
                  }
                : { paddingLeft: 30 }
            }
            onClick={() => dispatch({ type: TOOL_VIEW_SET, payload: false })}
          >
            <ImageSideBar src={unitsIcon} />
            Units
          </a>
          <a
            className={pathname.includes('/projects/') ? '' : 'disabled'}
            style={{ paddingLeft: 30 }}
            onClick={() =>
              pathname.includes('/projects/') && dispatch({ type: TOOL_VIEW_SET, payload: true })
            }
          >
            <img src={drawingIcon} />
            Drawing
          </a>
          <a className="disabled" style={{ paddingLeft: 30 }}>
            <ImageSideBar src={publishIcon} />
            Publish
          </a>
          {props.user && (
            <Link
              to={HOME_PATH}
              onClick={() => {
                dispatch(AuthService.logout());
                dispatch({ type: TOOL_VIEW_SET, payload: false });
              }}
              className="logout"
            >
              Log out
            </Link>
          )}
        </Nav>
      )}

      <div
        style={
          view
            ? { width: 'calc(100% - 75px)', marginLeft: 75 }
            : {
                marginLeft: 380,
                width: 'calc(100% - 380px)',
              }
        }
      >
        {props.children}
      </div>
    </div>
  );
};

export default SideBar;
