import React from "react";
import { ToolPanel } from "./drawing-view/ToolPanel";
import { Project } from "../../types/Project";
import { AppMode, ContextProvider } from "./context/AppModeContext";
import { HouseContextProvider } from "./context/HouseContext";
import { ToolboxContextProvider } from "./context/ToolboxContext";
import { Image } from "../../types/House";
import { DndProvider } from "react-dnd";
import { HTML5Backend } from "react-dnd-html5-backend";

export type ToolProps = {
	project: Project;
	updateProjectData?: (data: Project) => void;
	barVisible?: boolean;
	appMode?: AppMode;
	publishButtons?: boolean;
	stepMode?: boolean;
	setToolView?: (toolView: boolean) => void;
	tableButton?: boolean;
	fullMode?: boolean;
	isDrawingPage?: boolean;
	handleUndo?: any;
	handleRedo?: any;
};

const Tool = ({
	project,
	updateProjectData,
	barVisible,
	appMode,
	publishButtons,
	stepMode,
	setToolView,
	tableButton,
	fullMode,
	isDrawingPage,
	handleUndo,
	handleRedo,
}: ToolProps) => {
	const formatHousesData = () => {
		if (project && project.houses) {
			project.houses.forEach((house: any) => {
				house.imagesMap = new Map<string, Image>();
				if (!house.polygons) {
					house.polygons = [];
				}
			});
			if (project.projectProperties) {
				if (!project.projectProperties.guideLines) {
					project.projectProperties.guideLines = [];
				}
			}
		}

		return project;
	};

	return (
		<>
			<ContextProvider>
				<HouseContextProvider>
					<ToolboxContextProvider>
						<DndProvider backend={HTML5Backend}>
							<ToolPanel
								isDrawingPage={isDrawingPage}
								project={formatHousesData()}
								updateProjectData={updateProjectData}
								barVisible={barVisible}
								appMode={appMode || AppMode.edit}
								publishButtons={publishButtons}
								stepMode={stepMode}
								setToolView={setToolView}
								tableButton={tableButton}
								fullMode={fullMode}
								handleUndo={handleUndo}
								handleRedo={handleRedo}
							/>
						</DndProvider>
					</ToolboxContextProvider>
				</HouseContextProvider>
			</ContextProvider>
		</>
	);
};

export default Tool;
