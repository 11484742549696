import { House } from "../../types/House";
import cloneDeep from "clone-deep";

export const mapHousePropertiesToHouses = (houses: House[]) => {
	if (houses && houses.length > 0) {
		const housesCopy: House[] = cloneDeep(houses);
		return housesCopy.map((house: any) => {
			for (const [key, value] of Object.entries(house.houseProperties)) {
				house[key] = value;
			}
			return house;
		});
	}

	return [];
};

export const mapHousesToHouseProperties = (houses: any) => {
	if (houses && houses.length > 0) {
		const housesCopy: any = cloneDeep(houses);
		for (const [index, row] of Object.entries(housesCopy)) {
			for (const [key, value] of Object.entries(row as any)) {
				if (
					key !== "type" &&
					key !== "id" &&
					key !== "houseProperties" &&
					key !== "polygons" &&
					key !== "imagesMap"
				) {
					if (!housesCopy[index].houseProperties) {
						housesCopy[index].houseProperties = {};
					}
					housesCopy[index].houseProperties[key] = value;
					delete housesCopy[index][key];
				}
			}
		}

		return housesCopy;
	}

	return [];
};

export const prefillWithDefaultValuesForHouseProperties = (houses: House[]) => {
	const housesCopy: House[] = cloneDeep(houses);
	return housesCopy.map(house => {
		if (house.houseProperties) {
			if (!house.houseProperties.status) {
				house.houseProperties.status = "";
			}
			if (!house.houseProperties.roomsQuantity) {
				house.houseProperties.roomsQuantity = 0;
			}
			if (!house.houseProperties.orientation) {
				house.houseProperties.orientation = "";
			}
			if (!house.houseProperties.houseArea) {
				house.houseProperties.houseArea = 0;
			}
			if (!house.houseProperties.price) {
				house.houseProperties.price = 0;
			}
		}

		return house;
	});
};
