export const GO_STAGE = "GO_TO_NEXT_STAGE";
export const GO_TO_NEXT_STAGE = "GO_TO_NEXT_STAGE";
export const START_NEW_USER_FLOW = "START_NEW_USER_FLOW";
export const FINISH_NEW_USER_FLOW = "FINISH_NEW_USER_FLOW";

export const LOGIN_USER = "LOGIN_USER";
export const LOGOUT_USER = "LOGOUT_USER";

export const FETCH_STARTED = "FETCH_STARTED";
export const FETCH_SUCCESSFUL = "FETCH_SUCCESSFUL";
export const FETCH_FAILED = "FETCH_FAILED";

export const GET_USER = "GET_USER";

export const GET_PROJECT = "GET_PROJECT";

export const TOOL_VIEW_GET = "TOOL_VIEW_GET";
export const TOOL_VIEW_SET = "TOOL_VIEW_SET";

export const VALIDATE_INPUTS = "VALIDATE_INPUTS";

export const FETCH_STATE = "FETCH_STATE";
