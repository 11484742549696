import React from "react";
import RegisterForm from "./RegisterForm";
import AuthService from "../../../../services/AuthService";
import { useHistory } from "react-router";
import { useDispatch } from "react-redux";
import { loginUserAction } from "../../../redux/actions/UserActions";
import { toast } from "react-toastify";

const Register = (props: { onSubmit?: () => void }) => {
	const history = useHistory();
	const dispatch = useDispatch();
	return (
		<>
			<RegisterForm
				onSubmit={async (username, email, password) => {
					await AuthService.register(username, email, password)
						.then(() => {
							AuthService.login(username, password)
								.then(resp => {
									const user = resp.data.user;
									const token = resp.data.token;
									localStorage.removeItem("token");
									localStorage.setItem("token", token);
									dispatch(loginUserAction(user));
									history.push("/account");
								})
								.catch(err => alert(err.response.data.message || err.response.data));
						})
						.catch(() => toast.error("Error creating user"));
				}}
			/>
		</>
	);
};

export default Register;
